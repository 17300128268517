<div class="header">
   New Channel Experience
</div>
<mat-radio-group class="create-radio" [(ngModel)]="typeofChannel">
    <!-- <mat-radio-button [hidden]="true" value="{{channelType.NEW_CHANNEL}}">New Channel</mat-radio-button>
   <div [hidden]="true" *ngIf="typeofChannel === channelType.NEW_CHANNEL">
      <div for="slug" class="outline-div-input" style="margin-bottom: 10px;">
         <input  class="channel-input" id="slug" #slug placeholder="Enter Slug" style="cursor: auto"
         (paste)="slugValidationOnPaste($event)" (keypress)="slugValidation($event)" [(ngModel)]="channelSlug"
         autocomplete="off">

         <span class="help-text">
            <img src="assets/Channels/tooltip-icon.svg" alt="" width="14" height="14">
            <span class="text help-text-bottom">
               Add keyword to customize channel URL
            </span>
         </span>  
      </div>   
   </div> -->
    <mat-radio-button value="{{channelType.ATTACH_CHANNEL}}">Attach to channel</mat-radio-button>
    <div *ngIf="typeofChannel === channelType.ATTACH_CHANNEL">
        <div for="org-input" class="outline-div-input organization-select-div" style="margin-bottom: 14px;" (click)="dropDownDivOpen( 'Organization')" [ngClass]="ddOpen && ddName=='Organization' ? 'ddOpened' : ''">
            <input class="channel-input organization" id="org-input" #orgInput [placeholder]="masterChannel ? '' : 'Choose'" (blur)="dropDownDivOpen( '', 'true')"
               (keydown.space)="$event.stopPropagation()" autocomplete="off">
            <span class="organization-name display-selected" *ngIf="masterChannel">{{masterChannel.name}}</span>
            <img src="assets/Channels/Shape.svg" class="input-arrow">
         </div>
         <div class="channel-dropdown-panel organization" [ngClass]="ddOpen && ddName=='Organization' ? 'expanded mb-3' : ''">
            <div class="search-div org-search">
               <div class="org-search search-bar">
                  <input id="focusInput" type="text" [(ngModel)]="filterChannelText" value="" class="drop-down-search org-search" autocomplete="off"
                  placeholder="Search" (keydown.space)="$event.stopPropagation()" (keydown.enter)="applyChannelSearch()" style="padding-left: 16px;">
                  <img class="clear-search org-search" src="assets/Ads/close-grey.svg" (click)="clearChannelSearch()" *ngIf="showClear">
               </div>
               <span class="search-icon org-search">
                  <img height="18px" width="18px" src="assets/Ads/search.svg" class="org-search" (click)="applyChannelSearch()">
               </span>
            </div>
            <ng-container *ngIf="channelData | async as channelsList;else ticketLoader">
               <cdk-virtual-scroll-viewport itemSize="20" [ngStyle]="{'height.px': channels.length <  5 && channels.length >=1 ? channels.length*42 :210 }"
                  [ngClass]="{'option-block': channels.length==0} "
                  (scrolledIndexChange)="nextBatch((channelsList[channelsList.length-1]?.name))" class="option-block">
                  <div class="options-div" *ngFor="let channel of channels">
                     <span class="option-value" (mousedown)="setOrganization(channel)" [ngClass]="{'selected' : channel?.docId === masterChannel?.docId}">{{channel?.name}}</span>
                  </div>
                 <mat-spinner *ngIf="showSpinner" [diameter]="25" style="margin: 0 auto 6px;"></mat-spinner>
                 <span *ngIf="channels?.length == 0 && !showSpinner" class="no-record-found">
                  No result found
                 </span>
               </cdk-virtual-scroll-viewport>
            </ng-container>
            <ng-template #ticketLoader>
               <mat-spinner [diameter]="25" style="margin: 0 auto;height: 42px;"></mat-spinner>
           </ng-template>
         </div>
    </div>
</mat-radio-group>
<div class="button-area">
    <button type="button" class="cancel-button" (click)="close()">Cancel</button>
    <button type="button" class="apply-button" (click)="applyChanges()" [disabled]="disableApply()">Done</button>
</div>