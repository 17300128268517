<div class="d-flex dialog-body">
    <!-- <div class="dialog-align">
       <img src="{{data.imgSrc}}" [hidden]="data.imgHide">
    </div> -->
    <div class="body-container" [ngStyle]="{'border-color': borderColor}">
      <div class="message">
         <div>
            <span class="message-header">{{data.header}}</span>
            <p class="mb-0">{{data.message}}</p>
            <p class="sub-text">{{data.subText}}</p>
         </div>
         <img src="../../../assets/Alert_box/close_icon.svg" (click)="dialogRef.close()" class="close-icon">
      </div>
      <div class="button-div">
       <button type="button" class="btn btn-default btn-grey" (click)="dialogRef.close()">{{data.cancelText ? data.cancelText : 'Cancel'}}</button>
       <button type="button" *ngIf="data.popUpType !== 'Warning'" class="btn btn-default" [ngClass]="btnColor"  (click)="confirm()">{{data.buttonText}}</button>
      </div>   
    </div>
 </div>
