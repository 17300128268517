import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { CancelPopupComponent } from '../cancel-popup/cancel-popup.component';

@Component({
  selector: 'app-confirm-cancel-popup',
  templateUrl: './confirm-cancel-popup.component.html',
  styleUrls: ['./confirm-cancel-popup.component.scss']
})
export class ConfirmCancelPopupComponent implements OnInit {

  constructor(
    public dialog:MatDialog,
    public dialogRef:MatDialogRef<ConfirmCancelPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {header:string,message:string,action:string}
  ) { }

  ngOnInit(): void {
  }

  callModal(){
    this.dialogRef.close(false);
  }

  openCancelModal(){
    this.dialogRef.close(true);
    if(this.data.action ==='coverImage' || 'removeCoverImag') return
    if(this.data.action ==='app-version') return
    if(this.data.action ==='none') return
    let width:string = '500px';
    if(this.data.action === 'invite') width = '430px'
    this.dialog.open(CancelPopupComponent, {
      data: {cancelMessage: this.data.action},
      panelClass: 'cancel-action',
      width: width,
      position: {
        'top':'150px'
      },
      disableClose: true
    });
  }

}
