import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-failure-popup',
  templateUrl: './failure-popup.component.html',
  styleUrls: ['./failure-popup.component.scss']
})
export class FailurePopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<FailurePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data:{header: string,failureMessage: string, showClose?:boolean}
  ) { }
  ngOnInit(): void {
  }

}
