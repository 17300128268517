import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-season-ticket-list-popup',
  templateUrl: './season-ticket-list-popup.component.html',
  styleUrls: ['./season-ticket-list-popup.component.scss']
})
export class SeasonTicketListPopupComponent implements OnInit {
  seasonTicketsAttached: string[]
  constructor(
    @Inject(MAT_DIALOG_DATA)public data:string[]
  ) { 
    this.seasonTicketsAttached = data
  }

  ngOnInit(): void {
  }

}
