<div class="channel-table">
    <table mat-table [dataSource]="dataSource" class="stream-table">
        <!-- Stream Module -->
        <ng-container matColumnDef="team">
            <th mat-header-cell *matHeaderCellDef> Sub-Channel/Channel </th>
            <td mat-cell *matCellDef="let element;">
               <div class="flex-centered">
                  <ngx-skeleton-loader appearance="circle" [theme]="{width:'32px', height:'32px'}"></ngx-skeleton-loader>
                  <div class="pl-2 mw-200px">
                     <ngx-skeleton-loader count="2" [theme]="{
                        'border-radius': '5px',
                         height: '15px',
                         'width': '100px'
                      }"></ngx-skeleton-loader>
                  </div>
               </div>
            </td>
         </ng-container>
         <ng-container matColumnDef="country">
            <th mat-header-cell *matHeaderCellDef > COUNTRY </th>
            <td mat-cell *matCellDef="let element">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader>         
            </td>
         </ng-container>
         <ng-container matColumnDef="website">
            <th mat-header-cell *matHeaderCellDef > website </th>
            <td mat-cell *matCellDef="let element">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader>         
            </td>
         </ng-container>
         <ng-container matColumnDef="event">
            <th mat-header-cell *matHeaderCellDef > STREAM TITLE </th>
            <td mat-cell *matCellDef="let element">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader>            
            </td>
         </ng-container>
      
         <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> DESCRIPTION </th>
            <td mat-cell *matCellDef="let element">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader>    
            </td>
         </ng-container>
         
         <ng-container matColumnDef="seasonTicketsAttached">
            <th mat-header-cell *matHeaderCellDef>SEASON TICKETS</th>
            <td mat-cell *matCellDef="let element; let i=index"> 
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader>   
            </td>
         </ng-container>
      
         <ng-container matColumnDef="typeOfSport">
            <th mat-header-cell *matHeaderCellDef> TYPE OF SPORT </th>
            <td mat-cell *matCellDef="let element"> 
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader>   
            </td>
         </ng-container>
         
         <ng-container matColumnDef="stream">
            <th mat-header-cell *matHeaderCellDef> STREAMED USING </th>
            <td mat-cell *matCellDef="let element" >
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader>  
            </td>
         </ng-container>
         
         <ng-container matColumnDef="reservedInput">
            <th mat-header-cell *matHeaderCellDef> Reserved Input </th>
            <td mat-cell *matCellDef="let element" >
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader>  
            </td>
         </ng-container>
         
         <ng-container matColumnDef="lcStatus">
            <th mat-header-cell *matHeaderCellDef> LC STATUS </th>
            <td mat-cell *matCellDef="let element; let i = index">
               <ngx-skeleton-loader [theme]="{
                     'border-radius': '5px',
                     height: '15px'
               }"></ngx-skeleton-loader> 
            </td>
         </ng-container>
      
         <ng-container matColumnDef="startTime">
            <th mat-header-cell *matHeaderCellDef> START Time </th>
            <td mat-cell *matCellDef="let element">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px',
                  width: '100px'
               }"></ngx-skeleton-loader> 
            </td>
         </ng-container>
         <ng-container matColumnDef="duration">
            <th mat-header-cell *matHeaderCellDef> DURATION </th>
            <td mat-cell *matCellDef="let element"> 
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader> 
            </td>
         </ng-container>

         <ng-container matColumnDef="addPreroll">
            <th mat-header-cell *matHeaderCellDef>add Preroll</th>
            <td mat-cell *matCellDef="let element" id="toggle-cell">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader>
            </td>
        </ng-container>
      
         <ng-container matColumnDef="streamVisibility">
            <th mat-header-cell *matHeaderCellDef> Stream Visibility </th>
            <td mat-cell *matCellDef="let element">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader>  
            </td>
         </ng-container>
         <ng-container matColumnDef="buyers">
            <th mat-header-cell *matHeaderCellDef> Tickets Sold </th>
            <td mat-cell *matCellDef="let element; let i = index">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader>  
            </td>
         </ng-container>
         <ng-container matColumnDef="totalRevenue">
            <th mat-header-cell *matHeaderCellDef> TOTAL REVENUE </th>
            <td mat-cell *matCellDef="let element; let i = index">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader>   
            </td>
         </ng-container>
         <!-- Season Ticket Module -->
         <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> 
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader> 
          </ng-container>

         <ng-container matColumnDef="label">
            <th mat-header-cell *matHeaderCellDef> LABEL </th>
            <td mat-cell *matCellDef="let element"> 
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader> 
          </ng-container>

         <ng-container matColumnDef="icon">
            <th mat-header-cell *matHeaderCellDef> ICON </th>
            <td mat-cell *matCellDef="let element"> 
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader> 
          </ng-container>

         <ng-container matColumnDef="noOfValues">
            <th mat-header-cell *matHeaderCellDef> NO OF VALUES </th>
            <td mat-cell *matCellDef="let element"> 
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader> 
          </ng-container>
      
          <ng-container matColumnDef="validity">
            <th mat-header-cell *matHeaderCellDef> Validity </th>
            <td mat-cell *matCellDef="let element"> 
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader> 
               <ngx-skeleton-loader class="d-block" [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader> 
             </td>
          </ng-container>
      
          <ng-container matColumnDef="dateCreated">
            <th mat-header-cell *matHeaderCellDef> DATE CREATED </th>
            <td mat-cell *matCellDef="let element"> <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px'
            }"></ngx-skeleton-loader> 
            </td>
          </ng-container>
      
          <ng-container matColumnDef="createdBy">
            <th mat-header-cell *matHeaderCellDef> CREATED BY </th>
            <td mat-cell *matCellDef="let element">
               <div class="flex-centered"> 
                  <ngx-skeleton-loader  appearance="circle" [theme]="{width:'32px', height:'32px'}"></ngx-skeleton-loader>
                  <ngx-skeleton-loader [theme]="{
                     'border-radius': '5px',
                      height: '15px',
                      'width': '200px'
                   }"></ngx-skeleton-loader>
               </div>
            </td>
          </ng-container>
      
      
          <ng-container matColumnDef="noOfTicketSold">
           <th mat-header-cell *matHeaderCellDef style="min-width: 100px !important;"> NO. of Tickets Sold </th>
           <td mat-cell *matCellDef="let element"> 
              <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
                height: '15px'
               }"></ngx-skeleton-loader> 
            </td>
         </ng-container>
   
         <ng-container matColumnDef="link">
            <th mat-header-cell *matHeaderCellDef> Link </th>
            <td mat-cell *matCellDef="let element" title="copy link"> 
               <ngx-skeleton-loader appearance="circle" [theme]="{width:'32px', height:'32px'}"></ngx-skeleton-loader> 
             </td>
          </ng-container>
      
         <ng-container matColumnDef="gamesAttached">
           <th mat-header-cell *matHeaderCellDef style="min-width: 200px !important;"> Games Attached </th>
           <!-- <td mat-cell *matCellDef="let element"> {{element.gamesAttached}} </td> -->
           <td mat-cell *matCellDef="let element; let i = index">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
                height: '15px'
               }"></ngx-skeleton-loader> 
         </td>
         </ng-container>
         <ng-container matColumnDef="seasonTicketAttached">
            <th mat-header-cell *matHeaderCellDef style="min-width: 150px !important;"> Season Tickets Attached </th>
            <td mat-cell *matCellDef="let element; let i = index">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                   height: '15px'
                  }"></ngx-skeleton-loader>
          </td>
          </ng-container>

         <!-- Ads Module -->
         <ng-container matColumnDef="adName">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader> 
            </td>
        </ng-container>
        <ng-container matColumnDef="adType">
         <th mat-header-cell *matHeaderCellDef> Ad Type </th>
         <td mat-cell *matCellDef="let element">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px'
            }"></ngx-skeleton-loader> 
         </td>
     </ng-container>

        <ng-container matColumnDef="createdByUser">
            <th mat-header-cell *matHeaderCellDef> CREATED BY </th>
            <td mat-cell *matCellDef="let element">
               <div class="flex-centered">
                  <ngx-skeleton-loader appearance="circle" [theme]="{width:'32px', height:'32px'}"></ngx-skeleton-loader>
                  <div class="pl-2 mw-200px">
                     <ngx-skeleton-loader [theme]="{
                        'border-radius': '5px',
                         height: '15px',
                         'width': '200px'
                      }"></ngx-skeleton-loader>
                  </div>
               </div>
            </td>
         </ng-container>

         <ng-container matColumnDef="forOTT">
            <th mat-header-cell *matHeaderCellDef> For OTT </th>
            <td mat-cell *matCellDef="let element">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader> 
            </td>
        </ng-container>

         <!-- Reports module - livestream  -->
         <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> STREAM Title </th>
            <td mat-cell *matCellDef="let element"> 
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader> 
            </td>
         </ng-container>
         <ng-container matColumnDef="ticketSold">
            <th mat-header-cell *matHeaderCellDef> Tickets Sold </th>
            <td mat-cell *matCellDef="let element; let i = index">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader>  
            </td>
         </ng-container>

         <!-- Reports module channel -->
         <ng-container matColumnDef="clubName">
            <th mat-header-cell *matHeaderCellDef> Channel name </th>
            <td mat-cell *matCellDef="let element">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader> 
            </td>
         </ng-container>
         <ng-container matColumnDef="channelAdminDocId">
            <th mat-header-cell *matHeaderCellDef> Channel admin doc Id </th>
            <td mat-cell *matCellDef="let element">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader> 
            </td>
         </ng-container>
         <ng-container matColumnDef="adminEmail">
            <th mat-header-cell *matHeaderCellDef> Email of the admin </th>
            <td mat-cell *matCellDef="let element">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader> 
            </td>
         </ng-container>

         <!-- Reports Module Sub-channel -->
         <ng-container matColumnDef="teamName">
            <th mat-header-cell *matHeaderCellDef> Sub-Channel name </th>
            <td mat-cell *matCellDef="let element">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader> 
            </td>
         </ng-container>
         <ng-container matColumnDef="trainerDocId">
            <th mat-header-cell *matHeaderCellDef> Channel admin doc Id </th>
            <td mat-cell *matCellDef="let element">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader> 
            </td>
         </ng-container>
         <ng-container matColumnDef="trainerEmail">
            <th mat-header-cell *matHeaderCellDef> Email of the admin </th>
            <td mat-cell *matCellDef="let element">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader> 
            </td>
         </ng-container>

         <!-- Reports Module season ticket -->
         <ng-container matColumnDef="ticketName">
            <th mat-header-cell *matHeaderCellDef> Name of season ticket  </th>
            <td mat-cell *matCellDef="let element">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader> 
            </td>
        </ng-container>
        
        <ng-container matColumnDef="games">
            <th mat-header-cell *matHeaderCellDef> Number of games attached  </th>
            <td mat-cell *matCellDef="let element"> 
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader> 
            </td>
        </ng-container>
        
        <ng-container matColumnDef="ticketsSold">
            <th mat-header-cell *matHeaderCellDef> Number of tickets sold </th>
            <td mat-cell *matCellDef="let element"> 
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader> 
            </td>
        </ng-container>
        <!-- Reports Module buyers(overAll) -->
         <ng-container matColumnDef="eventName">
         <th mat-header-cell *matHeaderCellDef> Stream Name </th>
         <td mat-cell *matCellDef="let element"> 
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px'
            }"></ngx-skeleton-loader> 
         </td>
         </ng-container>
         <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let element">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader>
            </td>
        </ng-container>
        <!-- Joymo Share Module -->
        <ng-container matColumnDef="sendDate">
         <th mat-header-cell *matHeaderCellDef style="width: 160px;">Send Date</th>
         <td mat-cell *matCellDef="let element">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px'
            }"></ngx-skeleton-loader>
         </td>
      </ng-container>
      <ng-container matColumnDef="status">
         <th mat-header-cell *matHeaderCellDef style="width: 80px;">Status</th>
         <td mat-cell *matCellDef="let element">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px'
            }"></ngx-skeleton-loader>
         </td>
      </ng-container>
      <ng-container matColumnDef="notificationTitle">
         <th mat-header-cell *matHeaderCellDef>Title</th>
         <td mat-cell *matCellDef="let element">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px'
            }"></ngx-skeleton-loader>
         </td>
      </ng-container>
      <ng-container matColumnDef="content">
         <th mat-header-cell *matHeaderCellDef>Content</th>
         <td mat-cell *matCellDef="let element">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px'
            }"></ngx-skeleton-loader>
         </td>
      </ng-container>
      <ng-container matColumnDef="image">
         <th mat-header-cell *matHeaderCellDef style="width: 80px;">Image</th>
         <td mat-cell *matCellDef="let element;let i=index">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '48px',
               width:'98px'
            }"></ngx-skeleton-loader>
         </td>
      </ng-container>
      <ng-container matColumnDef="action">
         <th mat-header-cell *matHeaderCellDef style="width: 140px;">Action</th>
         <td mat-cell *matCellDef="let element">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px'
            }"></ngx-skeleton-loader>
         </td>
      </ng-container>
      <ng-container matColumnDef="recipient">
         <th mat-header-cell *matHeaderCellDef style="width: 140px;">Recipient</th>
         <td mat-cell *matCellDef="let element; let i = index">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px'
            }"></ngx-skeleton-loader>
         </td>
      </ng-container>

      <!-- Users Module -->
      <ng-container matColumnDef="role">
         <th mat-header-cell *matHeaderCellDef>Role</th>
         <td mat-cell *matCellDef="let element">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px'
            }"></ngx-skeleton-loader>
         </td>
      </ng-container>
      <ng-container matColumnDef="lastActivity">
         <th mat-header-cell *matHeaderCellDef>Last Activity</th>
         <td mat-cell *matCellDef="let element; let i = index">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px'
            }"></ngx-skeleton-loader>
         </td>
      </ng-container>

         <!-- Channel Module -->
        <ng-container matColumnDef="channelName">
            <th mat-header-cell *matHeaderCellDef>Channel</th>
            <td mat-cell *matCellDef="let element;">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader>
            </td>
        </ng-container>
        <ng-container matColumnDef="club">
         <th mat-header-cell *matHeaderCellDef>Channel</th>
         <td mat-cell *matCellDef="let element;">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px'
            }"></ngx-skeleton-loader>
         </td>
        </ng-container>
        <ng-container matColumnDef="sport">
            <th mat-header-cell *matHeaderCellDef>Sport</th>
            <td mat-cell *matCellDef="let element">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader>
            </td>
        </ng-container>
        <ng-container matColumnDef="lastLiveStream">
            <th mat-header-cell *matHeaderCellDef>Last Live Stream</th>
            <td mat-cell *matCellDef="let element">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader>
            </td>
        </ng-container>
        <ng-container matColumnDef="lastTicketSold">
            <th mat-header-cell *matHeaderCellDef>Last Ticket Sold</th>
            <td mat-cell *matCellDef="let element">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader>
            </td>
        </ng-container>
        <ng-container matColumnDef="createdDate">
            <th mat-header-cell *matHeaderCellDef>Created Time</th>
            <td mat-cell *matCellDef="let element;">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader>
            </td>
        </ng-container>
        <ng-container matColumnDef="createdIn">
         <th mat-header-cell *matHeaderCellDef>Created In</th>
         <td mat-cell *matCellDef="let element;">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px'
            }"></ngx-skeleton-loader>
         </td>
     </ng-container>
        <!-- Channel module - peding tab -->
        <ng-container matColumnDef="crmId">
            <th mat-header-cell *matHeaderCellDef>CRM Id</th>
            <td mat-cell *matCellDef="let element;">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader>
            </td>
        </ng-container>
        <ng-container matColumnDef="exclusive">
            <th mat-header-cell *matHeaderCellDef>Is Exclusive</th>
            <td mat-cell *matCellDef="let element">
               <ngx-skeleton-loader [theme]="{
                  'border-radius': '5px',
                  height: '15px'
               }"></ngx-skeleton-loader>
            </td>
        </ng-container>
        <!-- SubChannel Table -->
        <ng-container matColumnDef="subChannel">
         <th mat-header-cell *matHeaderCellDef>Sub-Channel</th>
         <td mat-cell *matCellDef="let element;">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px'
            }"></ngx-skeleton-loader>
         </td>
        </ng-container>
        <!-- Bulk import table -->
        <ng-container matColumnDef="bulkImportId">
         <th mat-header-cell *matHeaderCellDef>Bulk Import Id</th>
         <td mat-cell *matCellDef="let element"><ngx-skeleton-loader [theme]="{
            'border-radius': '5px',
            height: '15px'
         }"></ngx-skeleton-loader>
         </td>
        </ng-container>
        <ng-container matColumnDef="events">
         <th mat-header-cell *matHeaderCellDef>Streams</th>
         <td mat-cell *matCellDef="let element">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px'
            }"></ngx-skeleton-loader>
         </td>
        </ng-container>
       <ng-container matColumnDef="actions">
         <th mat-header-cell *matHeaderCellDef>Actions</th>
         <td mat-cell *matCellDef="let element">
            <ngx-skeleton-loader appearance="circle" [theme]="{width:'32px', height:'32px'}"></ngx-skeleton-loader> 
         </td>
        </ng-container>
        <!-- Channel Highlights -->
        <ng-container matColumnDef="select">
         <th mat-header-cell *matHeaderCellDef style="min-width: 100px !important;">
         </th>
         <td mat-cell *matCellDef="let clip ;">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px',
               width: '100px'
            }"></ngx-skeleton-loader>
         </td>
       </ng-container>
   
       <ng-container matColumnDef="video">
         <th mat-header-cell *matHeaderCellDef></th>
         <td mat-cell *matCellDef="let clip;">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '48px',
               width:'98px'
            }"></ngx-skeleton-loader>
         </td>
       </ng-container>
       <!-- Channel customization events -->
       <ng-container matColumnDef="eventTitle">
         <th mat-header-cell *matHeaderCellDef> Title </th>
         <td mat-cell *matCellDef="let element">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px',
               width: '100px'
            }"></ngx-skeleton-loader> 
         </td>
      </ng-container>
       <ng-container matColumnDef="endDate">
         <th mat-header-cell *matHeaderCellDef> End Date </th>
         <td mat-cell *matCellDef="let element">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px',
               width: '100px'
            }"></ngx-skeleton-loader> 
         </td>
      </ng-container>
      <ng-container matColumnDef="startDate">
         <th mat-header-cell *matHeaderCellDef> Start Date </th>
         <td mat-cell *matCellDef="let element">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px',
               width: '100px'
            }"></ngx-skeleton-loader> 
         </td>
      </ng-container>
      <ng-container matColumnDef="published">
         <th mat-header-cell *matHeaderCellDef> Published </th>
         <td mat-cell *matCellDef="let element">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px',
               width: '100px'
            }"></ngx-skeleton-loader> 
         </td>
      </ng-container>
      <ng-container matColumnDef="unarchive">
         <th mat-header-cell *matHeaderCellDef> Unarchive </th>
         <td mat-cell *matCellDef="let element">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px',
               width: '100px'
            }"></ngx-skeleton-loader> 
         </td>
      </ng-container>
      <ng-container matColumnDef="posterImage">
         <th mat-header-cell *matHeaderCellDef> Poster Image</th>
         <td mat-cell *matCellDef="let element;">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '48px',
               width:'98px'
            }"></ngx-skeleton-loader>
         </td>
      </ng-container>
         <!-- Channel customization tags -->
       <ng-container matColumnDef="tagName">
         <th mat-header-cell *matHeaderCellDef> Name </th>
         <td mat-cell *matCellDef="let element">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px',
               width: '100px'
            }"></ngx-skeleton-loader> 
         </td>
      </ng-container>
       <ng-container matColumnDef="tagDescription">
         <th mat-header-cell *matHeaderCellDef> Description </th>
         <td mat-cell *matCellDef="let element">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px',
               width: '100px'
            }"></ngx-skeleton-loader> 
         </td>
      </ng-container>
      <ng-container matColumnDef="coverImage">
         <th mat-header-cell *matHeaderCellDef> Cover Image</th>
         <td mat-cell *matCellDef="let element;">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '48px',
               width:'98px'
            }"></ngx-skeleton-loader>
         </td>
      </ng-container>
      <ng-container matColumnDef="tagType">
         <th mat-header-cell *matHeaderCellDef> Type </th>
         <td mat-cell *matCellDef="let element">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px',
               width: '100px'
            }"></ngx-skeleton-loader> 
         </td>
      </ng-container>
      <ng-container matColumnDef="channelType">
         <th mat-header-cell *matHeaderCellDef> New Channel Exp Type </th>
         <td mat-cell *matCellDef="let element">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px',
               width: '100px'
            }"></ngx-skeleton-loader> 
         </td>
      </ng-container>
      
      <!-- channel customisation clips -->
      <ng-container matColumnDef="streamTitle">
         <th mat-header-cell *matHeaderCellDef> Stream Title </th>
         <td mat-cell *matCellDef="let element">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px',
               width: '100px'
            }"></ngx-skeleton-loader> 
         </td>
      </ng-container>
      <ng-container matColumnDef="attachedEvent">
         <th mat-header-cell *matHeaderCellDef> Event </th>
         <td mat-cell *matCellDef="let element"> 
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px'
            }"></ngx-skeleton-loader>   
         </td>
      </ng-container>
      <ng-container matColumnDef="createdTime">
         <th mat-header-cell *matHeaderCellDef> Created Time </th>
         <td mat-cell *matCellDef="let element">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px',
               width: '100px'
            }"></ngx-skeleton-loader> 
         </td>
      </ng-container>
      <ng-container matColumnDef="videoPreview">
         <th mat-header-cell *matHeaderCellDef> Video Preview</th>
         <td mat-cell *matCellDef="let element;">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '48px',
               width:'98px'
            }"></ngx-skeleton-loader>
         </td>
       </ng-container>
       <ng-container matColumnDef="endTime">
         <th mat-header-cell *matHeaderCellDef> End Time </th>
         <td mat-cell *matCellDef="let element">
            <ngx-skeleton-loader [theme]="{
               'border-radius': '5px',
               height: '15px',
               width: '100px'
            }"></ngx-skeleton-loader> 
         </td>

         <ng-container matColumnDef="timestamp">
            <th mat-header-cell *matHeaderCellDef>POSTED TIME </th>
            <td mat-cell *matCellDef="let element">
                <ngx-skeleton-loader [theme]="{
                   'border-radius': '5px',
                   height: '15px'
                }"></ngx-skeleton-loader>
             </td>
        </ng-container>
        <ng-container matColumnDef="comments">
            <th mat-header-cell *matHeaderCellDef>COMMENTS </th>
            <td mat-cell *matCellDef="let element">
                <ngx-skeleton-loader [theme]="{
                   'border-radius': '5px',
                   height: '15px'
                }"></ngx-skeleton-loader>
             </td>
        </ng-container>
        <ng-container matColumnDef="videoType">
            <th mat-header-cell *matHeaderCellDef>VIDEO ACCESS TYPE </th>
            <td mat-cell *matCellDef="let element">
                <ngx-skeleton-loader [theme]="{
                   'border-radius': '5px',
                   height: '15px'
                }"></ngx-skeleton-loader>
             </td>
        </ng-container>
        <ng-container matColumnDef="Location">
            <th mat-header-cell *matHeaderCellDef>LOCATION </th>
            <td mat-cell *matCellDef="let element">
                <ngx-skeleton-loader [theme]="{
                   'border-radius': '5px',
                   height: '15px'
                }"></ngx-skeleton-loader>
             </td>
        </ng-container>
        <ng-container matColumnDef="feedback">
            <th mat-header-cell *matHeaderCellDef>FEEDBACK </th>
            <td mat-cell *matCellDef="let element">
                <ngx-skeleton-loader [theme]="{
                   'border-radius': '5px',
                   height: '15px'
                }"></ngx-skeleton-loader>
             </td>
        </ng-container>
        <ng-container matColumnDef="testingGroup">
            <th mat-header-cell *matHeaderCellDef>TESTING GROUP </th>
            <td mat-cell *matCellDef="let element">
                <ngx-skeleton-loader [theme]="{
                   'border-radius': '5px',
                   height: '15px'
                }"></ngx-skeleton-loader>
             </td>
        </ng-container>
        
      </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
    </table>
</div>