import firebase from "firebase/compat/app";

import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
@Injectable({
  providedIn: "root"
})
export class SettingsService {
  constructor(private firestore: AngularFirestore, private router: Router) { }

  getSettingsData(id: any) {
    // return this.firestore.collection("settings").snapshotChanges();
    return this.firestore.collection("settings" , ref => ref
    .where('backofficeAdminDocIds', 'array-contains', id)
    ).ref.get();
  }

  create(uid: any): Promise<any> {
    return this.firestore.collection('settings').doc("backoffice").update({ "backofficeAdminDocIds": firebase.firestore.FieldValue.arrayUnion(uid) });
  }

  delete(uid: any): Promise<any>{
    return this.firestore.collection("settings").doc("backoffice").update({"backofficeAdminDocIds": firebase.firestore.FieldValue.arrayRemove(uid)});
  }

}
