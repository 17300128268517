import { RouterModule, Routes } from '@angular/router';

import {AuthGuard} from './shared/userSession.service';
import {LoginComponent} from './login/login.component';
import { NgModule } from '@angular/core';
import { PageNotFoundComponent } from './home/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule),
  },
  {
    path: 'home/404',
    component: PageNotFoundComponent
  },
  {
    path:'**',
    redirectTo: '/home/404',
  },
  
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
