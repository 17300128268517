import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-cancel-popup",
  templateUrl: "./cancel-popup.component.html",
  styleUrls: ["./cancel-popup.component.scss"],
})
export class CancelPopupComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CancelPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { cancelMessage: string; cancelContext: string }
  ) {}

  ngOnInit(): void {}
}
