<div class="channel-view-header">
    <div class="container-fluid">
        <div class="row mob-nowrap">
            <div class="col-auto pl-0 channel-header__content">
                <span class="header-text">Manage Channels </span>
            </div>
            <div class="col-auto pl-0 channel-filter" [ngClass]="{'channeltab-filter':channelTabs == 1}">
                <div class="channel-filter__options">
                    <span class="channel-filter__lable">Filters</span>
                    <div class="filter-options">
                        <span class="filter-options__lable">
                            Channel Name
                        </span>
                        <div [ngClass]="{ disabled : isLoader}" style="position: relative;">
                            <input type="text" class="input-filter grey-bg" placeholder="Enter" autocomplete="off" [(ngModel)]="filterInput.clubName" id="createdName" (keyup.enter)="applyNameFilterOnEnter()" [disabled]="isLoader">
                            <img src="/assets/Channels/close.svg" *ngIf="filterInput.clubName" class="club-close" (click)="$event.stopPropagation();removeClubName()" />
                        </div>              
                    </div>
                    <div class="filter-options"  [hidden]="!this.isActiveTab() || isChannelTab()">
                        <span class="filter-options__lable">Channel Type</span>
                        <div [ngClass]="{ disabled : isLoader}">
                            <div class="mat-menu-country filter-type grey-bg" tabindex="0" #menuTypeTriggerBtn (keyup.enter)="$event.stopPropagation(); openCreatedTypeMenu()" [matMenuTriggerFor]="channelTypeDropDown">
                                <span class="mat-menu-time" [title]="filterInput.channelType" *ngIf="filterInput.channelType =='All' || filterInput.channelType == 'Attached Clubs' || filterInput.channelType == 'Unattached Clubs'">{{filterInput.channelType}}</span>
                                <i class="la la-angle-down timePickerArrow"></i>
                            </div>
                            <div class="time-list" >
                                <mat-menu #channelTypeDropDown="matMenu" xPosition="after" class="channel-type-dropdown">
                                    <div class="time-list-div">
                                        <div *ngFor="let channelType of channelTypeDropDownOptions" (click)="selectChannelType(channelType)"
                                            [ngClass]="{'selected': channelType == filterInput.channelType}">
                                            <button mat-menu-item [title]="channelType" (keydown.enter)="selectChannelType(channelType); onCreateTypeEnter($event); $event.stopPropagation()" [disabled]="isLoader">{{channelType}}</button>
                                        </div>
                                    </div>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                    <div class="filter-options">
                        <span class="filter-options__lable">Country</span>
                        <div [ngClass]="{ disabled : isLoader}">
                            <div class="mat-menu-country filter-country grey-bg" tabindex="0" (keyup.enter)="$event.stopPropagation(); openCountryMenu()" #menuCountryTriggerBtn [matMenuTriggerFor]="listOfCountry">
                                <span class="mat-menu-time" [title]="filterInput.country?.country" *ngIf="filterInput.country?.country">{{filterInput.country?.country}}</span>
                                <span class="mat-menu-time text-grey" *ngIf="!filterInput.country?.country">{{countryPlaceHolder}}</span>
                                <span style="align-items: center; display: flex; gap: 2px;">
                                    <img src="/assets/Channels/close.svg" *ngIf="filterInput.country?.country" class="country-close" (click)="$event.stopPropagation();removeCountryFilter()" />
                                    <i class="la la-angle-down timePickerArrow"></i>
                                </span>
                            </div>
                            <div class="country-list">
                                <mat-menu #listOfCountry="matMenu" xPosition="after" class="country-dropdown" >
                                    <div style="padding: 0 9px; position: relative;">
                                        <i class="las la-search"></i>
                                        <input type="text" [(ngModel)]='filterCountryText' value="" (keyup)="countrySearch()"
                                            placeholder="Search" (keydown)="$event.stopPropagation()" (click)="$event.stopPropagation()" autocomplete="off">
                                    </div>
                                    <mat-divider class="search-divider"></mat-divider>
                                    <div class="time-list-div">
                                        <div *ngFor="let country of countryList" (click)="setCountry(country)"
                                            [ngClass]="{'hidden-option':hideCountry(country), 
                                            'selected': country === filterInput.country}" [title]="country.country">
                                            <button mat-menu-item [disabled]="isLoader" (keydown.enter)="setCountry(country); onCountryEnter($event); $event.stopPropagation()">{{country.country}}</button>
                                        </div>
                                        <span *ngIf="filterCountry?.length == 0" class="no-record-found">
                                            No result found
                                            </span>
                                    </div>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                    <div class="filter-options" [hidden]="isChannelTab()">
                        <span class="filter-options__lable">Created From</span>
                        <div [ngClass]="{ disabled : isLoader}">
                            <div class="mat-menu-country filter-createrfrom grey-bg" tabindex="0" #menuFromTriggerBtn (keyup.enter)="$event.stopPropagation(); openCreatedFromMenu()" [matMenuTriggerFor]="channelCreatedDropDown">
                                <span class="mat-menu-time" [title]="this.filterInput.createdFrom" *ngIf="filterInput.createdFrom == 'All' || filterInput.createdFrom == 'Backoffice' || filterInput.createdFrom == 'Sign-ups'">{{this.filterInput.createdFrom}}</span>
                                <i class="la la-angle-down timePickerArrow"></i>
                            </div>
                            <div class="time-list">
                                <mat-menu #channelCreatedDropDown="matMenu" xPosition="after" class="channel-type-dropdown">
                                    <div class="time-list-div">
                                        <div *ngFor="let createdFrom of channelCreatedFromDropDown"
                                            [ngClass]="{'selected': createdFrom == filterInput.createdFrom}" (click)="selectChannelCreatedFrom(createdFrom)" >
                                            <button mat-menu-item [disabled]="isLoader" (keydown.enter)="selectChannelCreatedFrom(createdFrom); onCreateFromEnter($event); $event.stopPropagation()">{{createdFrom}}</button>
                                        </div>
                                    </div>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="channel-filter__buttons"  [hidden]="!showResetBtn()" >
                    <button type="button" tabindex="0" class="btn btn-default btn-white" (click)="clearFilter()" [ngClass]="{'btn-disabled':isLoader}">Reset</button>
                </div>
                <div class="channel-filter__buttons"  [hidden]="detectChanges()">
                    <button type="button" tabindex="0" class="btn btn-default btn-green" (click)="applyFilter()" [ngClass]="{'btn-disabled':isLoader}">Apply</button>
                    <button type="button" tabindex="0" class="btn btn-default btn-white" (click)="cancelFilter()" [ngClass]="{'btn-disabled':isLoader}">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>
<mat-tab-group disableRipple #channelMainTabs class="channel-main-tabs" (selectedIndexChange)="onChannelTabChanged($event)" [selectedIndex]="channelTabs" [ngClass]="isLoader ? 'cursor-default':''">
    <mat-tab label="Clubs"></mat-tab>
    <mat-tab label="Channels"></mat-tab>
</mat-tab-group>
<mat-tab-group disableRipple *ngIf="channelTabs == 0" #channelSubTabs class="channel-sub-tabs" (selectedIndexChange)="onTabChanged($event)" [selectedIndex]="tabs" [ngClass]="isLoader ? 'cursor-default':''">
    <mat-tab label="Active"></mat-tab>
    <mat-tab label="Archived"></mat-tab>
    <mat-tab label="Pending"></mat-tab>
</mat-tab-group>
<div class="loader-chart" *ngIf="isSpinner">
    <img src='../../assets/joymo-loader.svg' />
</div>
<div class="channel-body">
    <div class="channel-table-body">
        <ng-container *ngIf="infinite | async as clubs; else tempTable"> 
            <div class="channel-table">
                <cdk-virtual-scroll-viewport itemSize="2000" (scrolledIndexChange)="nextBatch($event,(clubs[clubs.length-1]?.name))" class="channel-table" [ngClass]="isLoader ? 'hide-table':''">
                    <table mat-table [dataSource]="dataSource" class="stream-table" [ngClass]="{'no-record':isNoRec}" matSort matSortDisableClear multiTemplateDataRows>
                        <ng-container matColumnDef="channelName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Channel</th>
                            <td mat-cell *matCellDef="let element">
                                <div class="flex-centered">
                                    <div class="team-avatar rounded-circle" [ngClass]="{'black':element?.color=='black'}">
                                        <img src="{{element?.imageUrl}}" class="channel-logo">
                                    </div>
                                    <div class="pl-2" title="{{element?.channelName}}">
                                        <span>{{element?.channelName | truncateString: 30}}</span>
                                        <span title="{{element?.email}}" style="color: #A3ACBB;"
                                            class="d-block">{{element?.email | truncateString : 30}}</span>
                                    </div>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="country">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Country</th>
                            <td mat-cell *matCellDef="let element">{{element?.country}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="sport">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sport</th>
                            <td mat-cell *matCellDef="let element">{{element?.sport}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="lastLiveStream">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Live Stream</th>
                            <td mat-cell *matCellDef="let element">{{element?.lastActivity}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="lastTicketSold">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Ticket Sold</th>
                            <td mat-cell *matCellDef="let element">{{element?.lastTicketSold}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="lastActivity">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Activity</th>
                            <td mat-cell *matCellDef="let element">{{element?.lastActivity}}
                               
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="createdBy">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
                            <td mat-cell *matCellDef="let element">{{element?.createdBy}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="exclusive">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Is Exclusive</th>
                            <td mat-cell *matCellDef="let element">{{element?.exclusive}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="description">
                            <th mat-header-cell *matHeaderCellDef>Description</th>
                            <td mat-cell *matCellDef="let element" title="{{element?.description}}">{{element?.description | truncateString: 30}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="website">
                            <th mat-header-cell *matHeaderCellDef >website</th>
                            <td mat-cell *matCellDef="let element">
                                <a (click)="$event.stopPropagation()" *ngIf="element?.website !== 'N/A'; else NA" href="{{element?.website}}" target="_blank">{{element?.channelName}}</a>
                                <ng-template #NA>N/A</ng-template>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="createdDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Created Time</th>
                            <td mat-cell *matCellDef="let element ; let i= index">
                                <div class="flex-centered justify-content-between" style="margin-right: 45px;">
                                    <span style="width: 140px;">{{element?.createdDate}}</span>
                                    <div class="ad-table-menus" *ngIf="showKebab()">
                                        <button type="button" [hidden]="tabs == tab.enabled" class="btn btn-transparent" [matMenuTriggerFor]="adMenu"
                                            (click)="$event.stopPropagation()">
                                            <img style="padding:0 14px 0 20px; cursor: pointer;"
                                                src="../../../assets/Channels/menu-dots.svg" id="table-menu-button">
                                        </button>
                                        <mat-menu #adMenu="matMenu" xPosition="before" backdropClass="ad-menu-overlay"
                                            class="ad-menu-panel">
                                            <button mat-menu-item *ngIf="isPending && !isRoleSales()" (click)="resendActivation(element.id)" [disabled]="!element.adminEmails?.length">Resend Activation</button>
                                            <button mat-menu-item routerLink="/home/channels/clubs/{{element?.id}}"
                                                *ngIf="!isPending">View Club</button>
                                            <button mat-menu-item (click)="editChannel($event,element.id, element.isMasterChannel)"
                                                *ngIf="!isArchived && isPending">Edit Club</button>
                                            <button mat-menu-item (click)="deleteInvite(element)"
                                                *ngIf="isPending == true && !isRoleSales()">Delete Club</button>
                                        </mat-menu>
                                        <button type="button" class="btn btn-transparent" (click)="displayExpandedRow(expandedElement===element, element.id, element.hasChannelExperience);
                                        expandedElement = expandedElement === element ? null : element; $event.stopPropagation()" [hidden]="!channelTypeFilterApplied || this.tabs != 0">
                                            <img style="padding-left: 14px; cursor: pointer;"
                                            src="../../../assets/Ads/arrow-down.svg" *ngIf="element!=expandedElement">
                                            <img style="padding-left: 14px; cursor: pointer;"
                                            src="../../../assets/Ads/arrow-up.svg" *ngIf="isPanelOpen && element==expandedElement">
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="createdIn">
                            <th mat-header-cell *matHeaderCellDef>Created In</th>
                            <td mat-cell *matCellDef="let element">{{element?.createdFrom}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="crmId">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Crm Id</th>
                            <td mat-cell *matCellDef="let element; let i=index">
                                {{element?.crmId}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                            <td mat-cell *matCellDef="let element; let index = index; let i = index">
                                <div class="flex-centered">
                                    <button type="button" class="btn btn-toggle" id="status"
                                        [ngClass]="!element?.status ? 'active': ''" [attr.aria-pressed]="!element?.status"
                                        autocomplete="off">
                                        <div class="handle"></div>
                                    </button> 
                                    <td style="width: 75px;">
                                        <span class="only-show-on-hover"
                                        *ngIf="(rowIndex===i || deviceType == 'mobile') && !element?.status">
                                        <img routerLink="/home/channels/{{element?.id}}"
                                            style="cursor: pointer;" src="assets/Datatable/view.svg">
                                        </span>
                                    </td>
                                    
                                </div>
                            </td>
                        </ng-container>
                        <!-- <ng-container matColumnDef="addPreroll">
                            <th mat-header-cell *matHeaderCellDef>add Preroll</th>
                            <td mat-cell *matCellDef="let element; let i = index;" class="toggle-cell" (click)="$event.stopPropagation()">
                                <div id="toggle-container">
                                    <mat-slide-toggle class="mat-toggle" id="{{element.id}}" [checked]="element.setAdPreroll"
                                    (change)="openUpdatePopup(element.id, element.setAdPreroll, element.defaultAdCuePoints)" [aria-label]="element.id">
                                    </mat-slide-toggle>

                                    <div class="preroll-tag" [ngClass]="{'disabled': !element.setAdPreroll}">
                                        <img src="../../../assets/Ads/arrow-down.svg" #prerollTagTrigger="matMenuTrigger" [matMenuTriggerFor]="tagMenu">
                                    </div>
                                    <mat-menu #tagMenu="matMenu" xPosition="after" backdropClass="tag-menu-overlay" class="tag-menu-panel">
                                        <div class="channel-preroll-tag-options" (click)="$event.stopPropagation()">
                                            <span class="tag-text" *ngIf="element.defaultAdCuePoints.tag && !showInput(i)">{{element.defaultAdCuePoints.tag}}</span>
                                            <span class="tag-tooltip" *ngIf="element.defaultAdCuePoints.tag && !showInput(i)">{{element.defaultAdCuePoints.tag}}</span>
                                            <input autocomplete="off" type="text" id="tagInput" *ngIf="!element.defaultAdCuePoints.tag || showInput(i)" (click)="setIndex(i,element.defaultAdCuePoints)" 
                                            (keyup.enter)="prerollTagTrigger.closeMenu()" (blur)="removeIndex(element.id, element.defaultAdCuePoints);" [(ngModel)]="element.defaultAdCuePoints.tag" placeholder="Add Tag">
                                            <label for="tagInput" [ngClass]="{'disabled' : !element.defaultAdCuePoints.tag}" class="tag-edit-label" (click)="setIndex(i,element.defaultAdCuePoints)">
                                                <img class="tag-edit" src="../../../assets/Channels/tag-edit.svg">
                                            </label>
                                            <span class="empty-border"></span>
                                            <img class="tag-copy" [ngClass]="{'disabled' : !element.defaultAdCuePoints.tag, 'copied': element.showCopy}" (click)="copyTag(element.defaultAdCuePoints, element.id)" src="../../../assets/Channels/tag-copy.svg">
                                        </div>
                                        <span role="alert" *ngIf="element.showCopy" class="tag-preroll-copied"><img src="assets/Alert_box/tick.svg" width="10" height="10"> <span style="padding-left: 4px"> Tag copied to clipboard</span></span>
                                    </mat-menu>
                                    <div class="ad-table-menus with-toggle">
                                        <button type="button" [hidden]="tabs == tab.enabled" class="btn btn-transparent" [matMenuTriggerFor]="adMenu">
                                            <img style="padding-left: 14px; cursor: pointer;"
                                                src="../../../assets/Channels/menu-dots.svg" id="table-menu-button">
                                        </button>
                                        <mat-menu #adMenu="matMenu" xPosition="before" backdropClass="ad-menu-overlay"
                                            class="ad-menu-panel">
                                            <button mat-menu-item *ngIf="isPending" (click)="resendActivation(element.id)">Resend Activation</button>
                                            <button mat-menu-item routerLink="/home/channels/{{element?.id}}"
                                                *ngIf="!isPending">View Channel</button>
                                            <button mat-menu-item (click)="editChannel($event,element.id, element.isMasterChannel)"
                                                *ngIf="!isArchived && isPending">Edit Channel</button>
                                            <button mat-menu-item (click)="deleteInvite(element)"
                                                *ngIf="isPending == true && !isRoleSales()">Delete Channel</button>
                                        </mat-menu>
                                    </div>
                                </div>
                            </td>
                        </ng-container> -->
                        <ng-container matColumnDef="channelType">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> New Channel Exp Type </th>
                            <td mat-cell *matCellDef="let element ; let i= index">
                                <div class="flex-centered justify-content-between">
                                    <span style="width: 140px;">{{element?.channelType }}</span>
                                    <div class="ad-table-menus" *ngIf="showKebab()" style="margin-right: 45px;">
                                        <!-- <button type="button" [hidden]="tabs == tab.enabled" class="btn btn-transparent" [matMenuTriggerFor]="adMenu"
                                            (click)="$event.stopPropagation()">
                                            <img style="padding:0 14px 0 20px; cursor: pointer;"
                                                src="../../../assets/Channels/menu-dots.svg" id="table-menu-button">
                                        </button>
                                        <mat-menu #adMenu="matMenu" xPosition="before" backdropClass="ad-menu-overlay"
                                            class="ad-menu-panel">
                                            <button mat-menu-item *ngIf="isPending && !isRoleSales()" (click)="resendActivation(element.id)" [disabled]="!element.adminEmails?.length">Resend Activation</button>
                                            <button mat-menu-item routerLink="/home/channels/{{element?.id}}"
                                                *ngIf="!isPending">View Channel</button>
                                            <button mat-menu-item (click)="editChannel($event,element.id, element.isMasterChannel)"
                                                *ngIf="!isArchived && isPending">Edit Channel</button>
                                            <button mat-menu-item (click)="deleteInvite(element)"
                                                *ngIf="isPending == true && !isRoleSales()">Delete Channel</button>
                                        </mat-menu> -->
                                        <button type="button" class="btn btn-transparent" (click)="displayExpandedRow(expandedElement===element, element.id, element.hasChannelExperience);
                                        expandedElement = expandedElement === element ? null : element; $event.stopPropagation()" *ngIf="!isArchived && !isPending">
                                            <img style="padding-left: 14px; cursor: pointer;"
                                            src="../../../assets/Ads/arrow-down.svg" *ngIf="element!=expandedElement">
                                            <img style="padding-left: 14px; cursor: pointer;"
                                            src="../../../assets/Ads/arrow-up.svg" *ngIf="isPanelOpen && element==expandedElement">
                                         </button>
                                    </div>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="expandedInfo">
                            <td mat-cell *matCellDef="let element; let i = index;" (click)="$event.stopPropagation()" [attr.colspan]="displayedColumns.length">
                                <div id="{{isPanelOpen && element==expandedElement ? 'expanded-audience-div' : '' }}"
                                [@detailExpand]="element==expandedElement ? 'expanded':'collapsed'">
                                    <div id="toggle-container" class="d-flex float-right toggle-container">
                                        <div *ngIf="!isArchived && !isPending && element.hasChannelExperience && !isPopupOpened">
                                            <span class="d-block preroll-header"> Master Channel </span>
                                            <div class="d-flex align-items-center" style="padding-top:6px;" routerLink="/home/channels/{{element.masterChannelId}}"> 
                                                <span style="cursor:pointer;" *ngIf="element?.masterChannelName !== ''" class="master-channel-name">{{element?.masterChannelName}}</span>
                                                <div *ngIf="element?.masterChannelName === ''">
                                                    <ngx-skeleton-loader count="1" [theme]="{
                                                    'border-radius': '5px',
                                                        height: '15px',
                                                        'width': '100px'
                                                    }"></ngx-skeleton-loader>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="delimiter" *ngIf="!isArchived && !isPending && element.hasChannelExperience && !isPopupOpened"></div>
                                        <div *ngIf="!isArchived && !isPending">
                                            <div class="d-flex align-items-center attach-container justify-content-between" style="padding-top:6px;"> 
                                                <span> <span class="attach-header" style="cursor:pointer;">Attach to a Channel</span> </span> 
                                                <mat-slide-toggle class="mat-toggle" id="{{element.id}}-newChannel" [checked]="element.hasChannelExperience" [disabled] = "element.hasChannelExperience"
                                                (change)="openNewChannelDialog(element.isMasterChannel, element.id,element.channelId, element.hasChannelExperience, element)">
                                                </mat-slide-toggle>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index" (mouseover)="rowIndex = i"
                            (mouseleave)="rowIndex = -1" id={{row.id}} (click)="navigateToChannelInfo(row.id)"
                            [ngStyle]="setRowPointer() && accessToSalesRole() && {'cursor': 'pointer'}"
                            class="example-element-row" [ngClass]="{'opened':isPanelOpen && row==expandedElement}"
                            [class.expanded-row]="expandedElement===row"></tr>
                        <tr mat-row *matRowDef="let row; columns: ['expandedInfo']" class="example-detail-row" [ngClass]="{'opened':isPanelOpen && row==expandedElement}"
                        ></tr>
                    </table>
                    <ng-container>
                        <div class="text-center mt-4" *ngIf="isNoRec">No results found</div>
                    </ng-container>
                <!-- <mat-spinner *ngIf="showSpinner" [diameter]="50" style="margin: 0 auto;"></mat-spinner> -->
            </cdk-virtual-scroll-viewport>
            <div class="channel-table" *ngIf="isLoader">
                <app-table-loader [displayedColumns]="displayedColumns"></app-table-loader>
            </div> 
            </div>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
        </ng-container> 
        <ng-template #tempTable>
            <div class="channel-table">
                <app-table-loader [displayedColumns]="displayedColumns"></app-table-loader>
            </div>
        </ng-template>
        <div class="fab" *ngIf="!isRoleSales()">
            <button type="button" class="btn" (click)="navigateToCreateChannelOrClub()">
                <i class="las la-plus"></i>
                <span>Create {{currentTabName}}</span>
            </button>
        </div>
    </div>
</div>