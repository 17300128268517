import { Component, Inject, OnInit} from '@angular/core';
import lodash, { chain, times } from 'lodash';

import { MatTableDataSource } from '@angular/material/table';
import { PaymentService } from 'src/app/shared/payments.service';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/shared/users.service';
import moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

enum ArchivedStatus{
  AUTO_ARCHIVED="AUTO_ARCHIVED"
}
interface BuyersList{
  docId: string,
  email: string,
}
@Component({
  selector: 'app-livestream-ticket-buyer-list',
  templateUrl: './livestream-ticket-buyer-list.component.html',
  styleUrls: ['./livestream-ticket-buyer-list.component.scss']
})
export class LivestreamTicketBuyerListComponent implements OnInit {
  ticketBuyerInfo: any = [];
  dataSource = new MatTableDataSource<any[]> (this.ticketBuyerInfo);
  displayedColumns = ['email', 'typeOfPurchase', 'price', 'purchasedDate']
  buyersEmail: { [docId: string]: BuyersList; }={}
  private ngUnsubscribe = new Subject()
  isNoRec: boolean = false
  isLoader: boolean = true
  livestreamPrice: string = ''
  vodPrice: string = ''
  bundlePrice:string = ''

  constructor(
    private paymentService: PaymentService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA)public data:{header: string, livestreamData: any},

  ) {
    times(2,()=> this.ticketBuyerInfo.push({
      email: '',
      price:'',
      date: '',
      typeOfPurchase: '',
      client:''
    }))
   }

  ngOnInit(): void {
    this.livestreamPrice = this.data?.livestreamData.livestreamPriceStatus !== 0? `${this.data?.livestreamData.forceCurrency} ${this.data?.livestreamData.livestreamPriceStatus}` :  'FREE'
    this.vodPrice = this.data?.livestreamData.vodPriceStatus !== 0? `${this.data?.livestreamData.forceCurrency}  ${this.data?.livestreamData.vodPriceStatus}` :  'FREE'
    this.bundlePrice = this.data?.livestreamData.bundlePriceStatus !== 0? `${this.data?.livestreamData.forceCurrency} ${this.data?.livestreamData.bundlePriceStatus}` :  'FREE'

    this.paymentService.getPaymentsByDocId(this.data.livestreamData.docId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(async (res)=> {
      let filterArr = res.filter((item: any) =>{
        if(this.data.livestreamData.archivedReason==ArchivedStatus.AUTO_ARCHIVED){
          return item
        }
        return !item.refund
      });

      if(!filterArr.length) {
        this.isNoRec = true;
      } else {
        this.isNoRec = false;
      }
      this.ticketBuyerInfo = []
      const userDocIds = filterArr.map((item:any)=> item.userDocId)
      await this.getEmail(userDocIds)
      filterArr.forEach((item: any) => {
        let priceAmount=item.stripeSession ? (item.stripeSession.amount_total ? `${this.data.livestreamData.forceCurrency} ${Number(item.stripeSession.amount_total)/100 }` : 'N/A') : 'N/A'
        this.ticketBuyerInfo.push({
          email: this.buyersEmail[item.userDocId].email || 'N/A',
          date: item.createdTime? moment(item.createdTime.toDate()).format('MMM DD YYYY HH:mm:ss'): 'N/A',
          price: item.integration_version ? `${this.data.livestreamData.forceCurrency} ${item.amount/100}` : `${this.data.livestreamData.forceCurrency} ${item.amount}`,
          typeOfPurchase: item.transactionType ? item.transactionType == 'ppv'? 'Livestream': item.transactionType === 'vod' ? 'VOD': 'Bundle' : 'N/A',
          client: item.clientSideConfirmation == true? 'Joymo Play':'Arena',
        })
      })
      this.isLoader = false
      this.dataSource = new MatTableDataSource(this.ticketBuyerInfo);
    })
  }
  async getEmail(userDocIds: string[]){
    const userDocs = await this.userService.getUsersByBatch(userDocIds)
    this.buyersEmail = chain(userDocs).map((item:any) => ({docId: item.id,email:item.data().email})).keyBy('docId').value()
  }
  ngOnDestroy(){
    this.ngUnsubscribe.next(null)
    this.ngUnsubscribe.complete()
  }

}
