import { COMMA, ENTER } from '@angular/cdk/keycodes'
import { Component, Inject, Input, OnInit } from '@angular/core';

import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isEmpty, isEqual } from 'lodash';
import { DialogService } from 'src/app/shared/dialog.service';
import { MasterChannelService } from 'src/app/shared/master-channel.service';
import { TeamsService } from 'src/app/shared/teams.service';
import { InternetLostComponent } from '../internet-lost/internet-lost.component';
import { SuccessPopupComponent } from '../success-popup/success-popup.component';
import { ChannelsService } from 'src/app/shared/channels.service';

@Component({
  selector: 'app-team-tags',
  templateUrl: './team-tags.component.html',
  styleUrls: ['./team-tags.component.scss']
})
export class TeamTagsComponent implements OnInit {

  defaultTags: string[]=[]
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  ddName:string = ""
  ddOpen:boolean = false;
  searchText:string= ""
  filterList: any
  selectedSource: Record<string, string> = {}
  eventSourceDocs!: any[]
  isSpinner: boolean = false
  isCreate: boolean = false
  channelDetails: Record<string, any> = {}
  isDefaultEventCleared: boolean = false;
  constructor(
    private dialogRef: MatDialogRef<TeamTagsComponent>,
    private masterChannelService: MasterChannelService,
    @Inject(MAT_DIALOG_DATA)public data:{channelDocId: string, teamDocId: string,
    defaultTags: string[], defaultEventId: string},
    private teamService: TeamsService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private channelsService:ChannelsService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.getEventSource()
    const teamSubs= this.teamService.getTeamById(this.data.teamDocId).subscribe((result:any)=>{
      this.channelDetails = result.channelDetails || {defaultTags: [], defaultEventId: null}
      this.defaultTags = !isEmpty(this.channelDetails.defaultTags) ? [...this.channelDetails.defaultTags] : []
      if(this.channelDetails.defaultEventId) this.findSource(this.channelDetails.defaultEventId)
      this.isCreate = isEmpty(this.channelDetails.defaultTags) && this.channelDetails.defaultEventId === null
      teamSubs.unsubscribe()
    })
    
  }

  async getEventSource(){
    if(!this.data.channelDocId) return
    const eventDocs = await this.masterChannelService.getEventSource(this.data.channelDocId)
    this.eventSourceDocs = eventDocs.docs.filter(events => !events.get("archived")).map(events => ({name: events.get("title"), docId: events.id}))
  }
  removeTag(tag: string): void {
    const index = this.defaultTags!.indexOf(tag);
    if (index >= 0) {
      this.defaultTags!.splice(index, 1);
    }
  }

  addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      const updatedTags = this.channelsService.splitTags(this.defaultTags,value)
      updatedTags.length && this.defaultTags.push(...updatedTags)
    }
    event.input.value = ''
  }
  dropDownDivOpen(dropName: string){
    if(this.isDefaultEventCleared){
      this.resetSearchText()
      return
    }
    this.ddName = dropName;
    this.ddOpen = true;
    if(dropName === "") this.resetSearchText()
  }
  resetSearchText(){
    this.searchText = "";
    this.filterList = undefined
    this.isDefaultEventCleared = false
  }
  findSource(sourceId:string){
    const selectedSource = this.eventSourceDocs.find(event => event.docId === sourceId)
    this.selectedSource.docId = selectedSource.docId
    this.selectedSource.name = selectedSource.name
  }
  filterSearch(name:string, searchText:string){
    return name?.toString()?.toLowerCase().indexOf(searchText.toLowerCase())==-1
  }

  setSource(sourceId: string, sourceName:string){
    this.selectedSource.docId = sourceId
    this.selectedSource.name = sourceName
    this.resetSearchText();
  }

  searchEvents(){
    this.filterList = this.eventSourceDocs.filter(event => event.name?.toString()?.toLowerCase().indexOf(this.searchText.toLowerCase()) != -1)
  }
  closeDialog(){
      this.dialogRef.close();
  }

  updateEventsTags(){
    if (!window.navigator.onLine){
      this.dialogService.openInternetLostDialog();
      return
    }
    this.isSpinner = true
    const defaultEventId =this.selectedSource?.docId || null
    this.teamService.updateTeam(this.data.teamDocId, this.defaultTags, defaultEventId).then(res=>{
      this.isSpinner = false
      this.dialogRef.close("success")
      this.openSuccessPopUp()
    })
  }

  disableCreate(){
    return isEmpty(this.defaultTags) && !this.selectedSource.docId
  }

  disableUpdate(){
    return isEqual(this.channelDetails.defaultTags, this.defaultTags) && this.channelDetails.defaultEventId === (this.selectedSource.docId || null)
  }
  openSuccessPopUp() {
    const successPopup = this.dialog.open(SuccessPopupComponent, {
      data: {
        successMessage: "Successfully added default tags and events",
      },
      width: "468px",
      position: {
        top: "13px",
      },
      disableClose: true,
      panelClass: "success-message-dialog",
    });
  }

  onDefaultEventClear(event:any){
    event.stopPropagation()
    this.selectedSource = {}
    this.isDefaultEventCleared = true
  }
}
