import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PageNotFoundService {

  private isPageNotFound = new BehaviorSubject<boolean> (false)
  checkPageNotFound = this.isPageNotFound.asObservable()
  constructor() { }
  setPageNotFound(){
    this.isPageNotFound.next(true)
  }
  resetPageNotFound(){
    this.isPageNotFound.next(false)
  }
}
