import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { chain, times } from 'lodash';
import { UserService } from 'src/app/shared/users.service';

interface UsersList{
  docId: string,
  email: string,
}
@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {
  userInfo: UsersList[] = [];
  dataSource = new MatTableDataSource<any> (this.userInfo);
  displayedColumns = ['userDocId', 'userName']
  buyersEmail: { [docId: string]: UsersList; }={}
  isNoRec: boolean = false
  isLoader: boolean = true
  livestreamPrice: string = ''
  vodPrice: string = ''
  bundlePrice:string = ''

  constructor(
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA)public data:{header: string,userDocIds: string[]},

  ) {
    times(2,()=> this.userInfo.push({
      docId: '',
      email: '',
    }))
   }

  async ngOnInit(): Promise<void> {

    this.userInfo = await this.getEmail(this.data.userDocIds)
    this.isLoader = false
    this.dataSource = new MatTableDataSource(this.userInfo);
  }
  async getEmail(userDocIds: string[]){
    const userDocs = await this.userService.getUsersByBatch(userDocIds)
    return userDocs.map((item:any) => ({docId: item.id,email:item.data().email || 'N/A'}))
  }
  ngOnDestroy(){
  }

}
