import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class PaymentService {
  constructor(private firestore: AngularFirestore,
    ) {}

  getPaymentsByDocId(id: string) {
    return this.firestore.collection(
      'payments',
      ref => ref
        .where('productDocId', '==', id)
        .where('confirmed', '==', true)
        .where('productType', '==', 'livestream')
    ).
    snapshotChanges()
    .pipe(
      map( changes => {
        return changes.map(a => {
          const data = a.payload.doc.data() as {};
          const docId = a.payload.doc.id;
          return { docId, ...data };
        });
      }));
  }

  getPaymentsTypeLiveStream(dateRange: any) {
    return this.firestore.collection(
      'payments',
      ref => ref
        .orderBy('createdTime', 'asc')
        .where('createdTime', '>=', new Date(dateRange.fromDate))
        .where('createdTime', '<=', new Date(dateRange.toDate))
        // .where('confirmed', '==', true)
        // .where('productType', '==', 'livestream')
    ).
    snapshotChanges()
    .pipe(
      map( changes => {
        return changes.map(a => {
          const data = a.payload.doc.data() as {};
          const docId = a.payload.doc.id;
          return { docId, ...data };
        });
      }));
  }
  getPaymentsByLiveStreamGroupDocId(id: string) {
    return this.firestore.collection(
      'payments',
      ref => ref
      .where('productDocId','==', id)
        .where('confirmed', '==', true)
        .where('productType', '==', 'livestreamGroup')
    )
    .get()
      .pipe(
        map( changes => {
          return changes.docs.map(doc => {
            const data = doc.data() as any;
            const docId = doc.id;
            return { docId, ...data };
          });
        })).toPromise();
  }
}
