<div class="main-container">
   <div class="dialog-body">
      <div class="dialog-align">
         <img src="assets/Alert_box/error_icon.svg">
         <h2 class="message" *ngIf="data.cancelMessage === 'creation'">Notification {{data.cancelMessage}} cancelled</h2>
         <h2 class="message" *ngIf="data.cancelMessage === 'updation'">Notification updates discarded</h2>
         <h2 class="message" *ngIf="data.cancelMessage === 'invite'">Invitation cancelled</h2>
         <h2 class="message" *ngIf="data.cancelMessage === 'createSubChannel'">Sub-Channel Creation Cancelled</h2>
         <h2 class="message" *ngIf="data.cancelMessage === 'channelCreation'"> Channel creation cancelled</h2>
         <h2 class="message" *ngIf="data.cancelMessage === 'channelUpdation'"> Channel updates discarded</h2>
         <h2 class="message" *ngIf="data.cancelMessage === 'emailTemplateUpdation'"> Email Template updates discarded</h2>
         <h2 class="message" *ngIf="data.cancelMessage ===  'bulkCreation'">Bulk Import creation cancelled</h2>
         <h2 class="message" *ngIf="data.cancelMessage ===  'subChannelCoverImage'">Cover Image Upload cancelled</h2>
         <h2 class="message" *ngIf="data.cancelMessage ===  'channelPendingState'">Oops! Something went wrong</h2>
         <h2 class="message" *ngIf="data.cancelMessage ===  'channelHighlights'">Highlight Upload cancelled</h2>
         <h2 class="message" *ngIf="data.cancelMessage === 'Codebase creation'">{{data.cancelMessage}} cancelled</h2>
         <h2 class="message" *ngIf="data.cancelMessage === 'Codebase updation'">Codebase updates discarded</h2>
         <h2 class="message" *ngIf="data.cancelMessage === 'deleteStream'">{{data.cancelContext}}</h2>
         <h2 class="message" *ngIf="data.cancelMessage === 'ticketCancelled'">{{data.cancelContext}}</h2>
         <h2 class="message" *ngIf="data.cancelMessage === 'cancelled'">{{data.cancelContext}}</h2>
         <h2 class="message" *ngIf="data.cancelMessage === 'tagCreation'">Named Tag creation cancelled</h2>
         <h2 class="message" *ngIf="data.cancelMessage === 'tagUpdates'">Named Tag updates discarded</h2>
         <h2 class="message" *ngIf="data.cancelMessage === 'consentCreation'">Consent creation cancelled</h2>
         <h2 class="message" *ngIf="data.cancelMessage === 'termCreation'">Term creation cancelled</h2>
         <h2 class="message" *ngIf="data.cancelMessage === 'consentEdit'">Consent edit cancelled</h2>
         <h2 class="message" *ngIf="data.cancelMessage === 'termEdit'">Term edit cancelled</h2>
         <h2 class="message" *ngIf="data.cancelMessage === 'versionCreation'">Version creation cancelled</h2>
      </div>
      <img class="close-dialog" src="assets/Alert_box/close_icon.svg" (click)="dialogRef.close()">
   </div>
</div>
<!-- <div class="modal-footer">
 <button type="button" class="btn btn-default btn-white" (click)="dialogRef.close()">Close</button>
</div> -->