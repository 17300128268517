import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { COMMA, ENTER, I } from '@angular/cdk/keycodes'
import { Observable, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { ValidateService } from 'src/app/shared/validate.service';


@Component({
  selector: 'app-email-recipients',
  templateUrl: './email-recipients.component.html',
  styleUrls: ['./email-recipients.component.scss']
})
export class EmailRecipientsComponent implements OnInit {
  newEmail = new FormControl('')
  validNewEmail : boolean = false
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  addOnBlur = true;
  options: string[] = [];
  filteredOptions!: Observable<string[]>;
  emails: string[] = []
  constructor(
    private validateService: ValidateService,
    public dialogRef: MatDialogRef<EmailRecipientsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { emails: string[] , autofillOptions: string[]}
  ) {}

  ngOnInit(): void {
    this.filteredOptions = this.newEmail.valueChanges.pipe(
      startWith(''),
      map((value :string) => this._filter(value || '')),
    );
    this.emails = [ ... this.data.emails]
    this.options = this.data.autofillOptions
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    this.validNewEmail = this.validateEmail() ? true : false
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }
  addEmail(event: string): void {
    if(!this.emails.includes(event) && this.validNewEmail && event.length > 0){
      this.emails.push(event);
      this.newEmail.reset('')
    }else{
      this.newEmail.reset('')
    }
  }

  removeEmail(email: string): void {
    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }

  validateEmail(){
    return this.validateService.validateEmail(this.newEmail.value)
  }
  saveEditedEmails(): void{
    this.data.emails.length = 0;
    this.data.emails.push(...this.emails);
    this.dialogRef.close()
  }
}
