import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { chain, keyBy, times, uniq } from 'lodash';
import { DownloadCSVService } from 'src/app/shared/downloadCSV.service';
import { PaymentService } from 'src/app/shared/payments.service';
import { UserService } from 'src/app/shared/users.service';

interface BuyersList{
  docId: string,
  email: string,
  noOfTicketsPurchased: number
}
@Component({
  selector: 'app-season-ticket-buyers-list',
  templateUrl: './season-ticket-buyers-list.component.html',
  styleUrls: ['./season-ticket-buyers-list.component.scss']
})
export class SeasonTicketBuyersListComponent implements OnInit {
  header: string
  excelData: any=[]
  fileName: string
  isLoader: boolean = true
  displayedColumns: string[] = ["email","noOfTicketsPurchased"]
  dataList: BuyersList[]=[] 
  dataSource = new MatTableDataSource<BuyersList> (this.dataList);
  buyerDocIds: string[]
  constructor(
    private downloadCSVService: DownloadCSVService,
    private userService: UserService,
    private paymentsService: PaymentService,
    @Inject(MAT_DIALOG_DATA)public data:{header: string, fileName: string, buyerDocIds?: string[]}
  ) { 
    this.header = data.header
    this.fileName = data.fileName || ''
    this.buyerDocIds = data.buyerDocIds || []
    times(2,()=> this.dataList.push({
      email: '',
      docId:'',
      noOfTicketsPurchased: 0
    }))
  }

  async ngOnInit(): Promise<void> {
    if(this.buyerDocIds.length == 0){
      const paymentDocs = await this.paymentsService.getPaymentsByLiveStreamGroupDocId(this.fileName)
      this.buyerDocIds= paymentDocs.map(item=> item.userDocId)
    }
    const ticketsPurchased = chain(this.buyerDocIds).countBy().map((count,id)=>({docId: id,noOfTicketsPurchased:count})).value()
    
    const userDocs = await this.userService.getUsersByBatch(uniq(this.buyerDocIds))
    const buyersEmail = userDocs.map((item:any) => ({docId: item.id,email:item.data().email || 'N/A'}))
    
    const buyersMap = keyBy(ticketsPurchased, 'docId')

    this.dataList = buyersEmail.map((item:any) => Object.assign({}, item, buyersMap[item?.docId] || {}));
    this.dataSource = new MatTableDataSource(this.dataList);
    this.excelData = this.dataList.map((item:any) => ({"Email":item.email,"No. of Tickets Purchased": item.noOfTicketsPurchased}))
    this.isLoader = false
  }
  exportToExcel(){
    if(this.excelData.length === 0 ) return
    this.downloadCSVService.downloadFile(this.excelData, 
      `BuyersList_${this.fileName}`,
      Object.keys(this.excelData[0])
      )
  }
}
