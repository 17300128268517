<h2>Add Email Recipients</h2>
<div appearance="fill" class="email-textarea" (click)="trigger.openPanel()">
    <mat-chip-list #chipList aria-label="Tag selection" [selectable]="false" class="email-chip-list" panelClass="email-chip-list-panel">
        <mat-chip *ngFor="let email of emails; let ind = index" (removed)="removeEmail(email); trigger.closePanel()" disableRipple
            [selected]="false" title="{{email}}">
            {{email | truncateString: 20}}
            <img matChipRemove class="close-icon" src="assets/Channels/tag-remove.svg">
        </mat-chip>
        <input [placeholder]="emails?.length<3 ? 'Add Email ID' : ''" [formControl]="newEmail" [matChipInputFor]="chipList" id="bulkTags" (focus)="trigger.openPanel()"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur" [disabled]="emails?.length>=3"
            [matAutocomplete]="auto" (matChipInputTokenEnd)="addEmail(newEmail.value)" #trigger="matAutocompleteTrigger">
        <mat-autocomplete #auto="matAutocomplete" #bulkTags>
            <ng-container *ngIf="emails?.length<3">
                <mat-option *ngFor="let option of filteredOptions | async" [value]="option" (click)="addEmail(option); trigger.openPanel()" [disabled]="emails.includes(option)">
                    {{option}}
                </mat-option>
                <mat-option *ngIf="newEmail.value.length>0" class="add-option" [ngClass]="{'disable' : !validNewEmail }"
                    [value]="newEmail.value" (click)="addEmail(newEmail.value)">
                    <img src="assets/Channels/plus-circle.svg">
                    <span>Add</span>
                    "{{newEmail.value}}"
                </mat-option>
            </ng-container>
        </mat-autocomplete>
    </mat-chip-list>
</div>
<div class="action-btns">
    <button class="cancel-btn" (click)="dialogRef.close()">Cancel</button>
    <button class="done-btn" (click)="saveEditedEmails()">Done</button>
</div>