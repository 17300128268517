import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {
  errorMessage: string= ''
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {fileSize: string, message:string, isVideo?:boolean, format?:string, isDuration?:boolean, issue?:string}
  ) { }

  ngOnInit(): void {
    const format = this.data.format ? this.data.format : '.png or .jpg'
    this.errorMessage = `The file you selected is either not supported or too large, please use ${format} images smaller than ${this.data.fileSize} only.`
  }

}
