<div class="d-flex dialog-body">
    <!-- <div> -->
      <!-- <img class="error-icon" src="assets/Alert_box/warning_icon.svg"> -->
      <!-- <div class="error-icon">
         <div class="error-icon-div"></div>
      </div> -->
      <div class="body-container">
         <div class="message">
            <p class="mb-0">No donut! The internet connection is lost. Can you please check your settings?</p>
         </div>
         <!-- <div class="button-div">
            <button type="button" class="btn btn-default btn-grey" mat-dialog-close>Okay</button>
         </div> -->
      </div>
      <!-- <img class="close-icon" src="assets/Alert_box/close_icon.svg"> -->
      <!-- <div class="close-icon" (click)="dialogRef.close()">
         <div class="close-icon-div"></div>
      </div> -->
    <!-- </div> -->
 </div>
<!-- <div class="modal-footer">
 <button type="button" class="btn btn-default btn-white" (click)="dialogRef.close()">Okay</button>
</div> -->