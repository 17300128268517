import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ChannelsService, ChannelType } from 'src/app/shared/channels.service';
import { map, mergeMap, scan } from 'rxjs/operators';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { isEmpty } from 'lodash';

interface channelData{
  name: string,
  docId: string
}
@Component({
  selector: 'app-new-channel-experience',
  templateUrl: './new-channel-experience.component.html',
  styleUrls: ['./new-channel-experience.component.scss']
})
export class NewChannelExperienceComponent implements OnInit {

  channelOffset = new BehaviorSubject(null)
  clubSubs: Subscription = new Subscription();
  channelData!: Observable<any[]>
  showSpinner: boolean = false
  filterChannelText: string  = ''
  showClear: boolean = false
  @ViewChild(CdkVirtualScrollViewport) viewport!: CdkVirtualScrollViewport
  channels: channelData[] = []
  ddOpen:boolean = false
  ddName:string = ''
  dropdownListener: EventListener
  masterChannel!: channelData
  typeofChannel: string = ''
  channelType = ChannelType
  channelSlug: string = ''
  endOfClub: boolean = false;
  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)public data:{typeofChannel: boolean},
    private channelsService: ChannelsService,
    private dialogRef: MatDialogRef<NewChannelExperienceComponent>,
  ) { 
    this.dropdownListener = (e: Event) => this.closeOrganizationDropdown(e);
    this.typeofChannel = this.channelType.ATTACH_CHANNEL
  }

  ngOnInit(): void {
    this.getClub()
  }

  getClub(){
    const batchMap= this.channelOffset.pipe(
      mergeMap((n:any)=> this.channelsService.getBatch("channels",n)),
      scan((acc, batch)=>{
        return {...acc,...batch};
      },{})
    )
    this.channelData= batchMap.pipe(map(v=> Object.values(v)))
    this.clubSubs=this.channelData.subscribe(result=>{
      this.channels = this.optionGrouping(result)
      this.showSpinner=false
    })
  }
  applyChannelSearch(){
    if(this.filterChannelText=='') return
    // this.searchFilterText=this.filterChannelText
    this.showClear=true
    this.clubSubs.unsubscribe()
    this.showSpinner = true
    this.channels = []
    this.endOfClub = true
    const channelSubs = this.channelsService.getClubByName("channels",this.filterChannelText).subscribe(result=>{
      const filteredChannel = result.filter((item:any) =>  item.name.toLowerCase().includes(this.filterChannelText.toLowerCase()))
      
      this.channels = this.optionGrouping(filteredChannel)
      this.showSpinner = false
      channelSubs.unsubscribe()
    })
  }
  clearChannelSearch(){
    this.clubSubs.unsubscribe()
    this.filterChannelText = ''
    // this.searchFilterText = ''
    // this.showSpinner = true
    this.channels = []
    this.endOfClub = false
    this.channelOffset.next(null)
    this.nextBatch('')
    this.getClub()
    this.showClear=false
  }
  nextBatch(offset:any){
    if( this.endOfClub) {
      // this.showSpinner=false
      return
    }
    const channelEnd= this.viewport.getRenderedRange().end
    const channelTotal = this.viewport.getDataLength()
    // if(channelEnd === channelTotal) {
      this.channelOffset.next(offset)
      this.showSpinner= true
    // }
  }
  optionGrouping(optionValues:any[]){
    return optionValues.filter(item => !item.archived).map((item =>
      ({
        name: item.name,
        docId: item.id
      })
    ))
  }
  dropDownDivOpen(dropName: string, fromBlur?:string){
    if(dropName === "Organization") window.addEventListener("click", this.dropdownListener, false)
    if(dropName === "") window.removeEventListener("click",this.dropdownListener, false)
    if(this.showClear) this.clearChannelSearch();
    if(fromBlur && !this.ddOpen) return
    this.ddOpen = true;
    this.ddName = dropName;
    this.resetFilter()
  }
  resetFilter(){
    // this.filterDropText=''
    // this.filterCountryText = ""
    // this.filterSportText = ""
    // this.filterCurrencyText = ""
    // this.filterList=undefined
  }
  closeOrganizationDropdown(evt: any){
    let className:string = evt.target.className
    if(className.includes("organization-select-div")) return
    if(className.includes("org-search")) return
    this.dropDownDivOpen("")
  }
  setOrganization(channel:channelData){
    this.masterChannel = channel
  }
  hideChannel(channel: channelData){
    return (channel.name).toString().toLowerCase().indexOf(this.filterChannelText.toLowerCase()) == -1
  }
  close(){
    this.dialogRef.close()
  }
  applyChanges(){
    const data=this.typeofChannel === this.channelType.ATTACH_CHANNEL ?{
      typeofChannel :this.typeofChannel,
      channelId: this.masterChannel?.docId || '',
      channelName: this.masterChannel?.name || ''
    }:
    {
      typeofChannel :this.typeofChannel,
      slug: this.channelSlug
    }
    this.dialogRef.close(data)
  }
  ngOnDestroy(){
    if(this.clubSubs) this.clubSubs.unsubscribe()
    this.endOfClub=false
  }
  disableApply(){
    if(this.typeofChannel === this.channelType.ATTACH_CHANNEL) return this.masterChannel === undefined
    return isEmpty(this.channelSlug)
  }
  slugValidation(event: KeyboardEvent){
    let currentSlug = this.channelSlug + event.key + "slug"
    let regex = /^[a-zA-Z0-9]+((-{1})([a-z0-9A-Z])+){0,}$/;
    if(currentSlug.match(regex)){
      return true
    }
    return false
  }

  slugValidationOnPaste(event: ClipboardEvent){
    let copiedSlug = event.clipboardData?.getData('text') || ""
    let regex = /^[a-zA-Z0-9]+((-{1})([a-z0-9A-Z])+){0,}$/;
    if(copiedSlug.match(regex)){
      return true
    }
    return false
  }
}
