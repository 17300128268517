import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'truncateString' })
export class TruncateString implements PipeTransform {
  transform(value: string, strlen: number) {
    if (value) {
      if (value.length <= strlen) {
        return value + '\n';
      }
      return value.substring(0, strlen) + '...';
    }
    return '';
  }
}
