import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from 'src/app/shared/dialog.service';


const ACCEPTED_EVENT_POSTER_SIZE = 2 * 1024 * 1024;
const ACCEPTED_EVENT_POSTER_TYPE = ["image/png", "image/jpeg"];
@Component({
  selector: 'app-events-tags-image',
  templateUrl: './events-tags-image.component.html',
  styleUrls: ['./events-tags-image.component.scss']
})
export class EventsTagsImageComponent implements OnInit {
  posterCoverImage: string | null
  imageLoaded: boolean = false
  @ViewChild('image') image!: ElementRef
  imageFile: File | undefined
  tabType: string  = ''
  constructor(
    @Inject(MAT_DIALOG_DATA)public data:{imgUrl: string, type: string},
    private dialogService: DialogService,
    private dialogRef: MatDialogRef<EventsTagsImageComponent>
  ) {
    this.posterCoverImage = data.imgUrl
    this.tabType = data.type
   }

  ngOnInit(): void {
    this.dialogRef.beforeClosed().subscribe(()=> this.dialogRef.close({imageUrl: this.posterCoverImage, imageFile: this.imageFile}))
  }

  onLoadImage(){
    this.imageLoaded = true
  }

  onDragOver(event: DragEvent){
    event.preventDefault()
  }

  onDropSuccess(event:DragEvent, dragField: string){
    event.preventDefault()
    const selectedFile = event.dataTransfer?.files;
    const target = {
      target: {
        files: selectedFile
      }
    }
    this.imageHandler(selectedFile,target, dragField)
  }

  imageUpload(event: any, dragField: string){
    const selectedFile = event.target.files;
    this.imageHandler(selectedFile,event, dragField)
  }

  async imageHandler(selectedFile: any,event: any, dragField: string){
    let file = selectedFile.item(0)
    if(file.size > ACCEPTED_EVENT_POSTER_SIZE || !ACCEPTED_EVENT_POSTER_TYPE.includes(file.type)){
      this.dialogRef.addPanelClass("hide-image-dialog")
      this.openError("2 MB");
      this.image.nativeElement.value = null;
      return
    }

    this.dialogRef.addPanelClass("hide-image-dialog")
    const fileType = selectedFile.item(0).type
    const format = fileType.substring(fileType.indexOf('/')+1, fileType.length)
    let data = {
      cropEvent: event,
      format: format,
      aspectRatio: dragField === 'EVENT' ? 16/9 : 3/1
    }

    const cropImage = this.dialogService.openImageCropperDialog(data)
    cropImage.afterClosed().subscribe(res => {
      if(res.croppedImage){
        this.dialogRef.removePanelClass("hide-image-dialog")
        this.posterCoverImage = res.croppedImage;
        this.imageFile = selectedFile.item(0);
        this.image.nativeElement.value=null
      }else{
        this.dialogRef.removePanelClass("hide-image-dialog")
        this.image.nativeElement.value = null;
      }
    })
    
  }
  openError(size:string){
    let message:string = ""
    const data = {
      fileSize: size,
      message: message,
      format:'.jpg, .png'
    }
    this.dialogService.openErrorDialog(data).afterClosed().subscribe(()=>{
      this.dialogRef.removePanelClass("hide-image-dialog")
    })
  } 
  removeImage(){
    this.posterCoverImage = null
    this.imageFile=undefined
  }
}
