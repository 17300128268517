import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Team } from "../interfaces/teams";
import {map} from 'rxjs/operators';
import { AuthGuard, Role } from "./userSession.service";
import { chunk } from "lodash";
import firebase from "firebase/compat/app";
import { AngularFireFunctions } from "@angular/fire/compat/functions";
export enum ChannelInfoTabs{
  STREAMS = "Streams",
  TEAMS = "Sub-Channels",
  USERS = "Users",
  HIGHLIGHTS = "Highlights",
  CLIPS = "Clips",
}

//the order of channel info tabs should be the same that is expected in UI
// export enum ChannelInfoTabs{
//   HOME = "Home",
//   STREAMS = "Streams",
//   TEAMS = "Sub-Channels",
//   USERS = "Users",
//   HIGHLIGHTS = "Highlights",
//   CLIPS = "Clips",
// }

// export enum MasterChannelInfoTabs{
//   HOME = "Home",
//   VIDEOS = "Videos",
//   EVENTS = "Events",
//   NAMED_TAGS = "Named Tags",
//   USERS = "Users",
//   TEAMS = "Sub-Channels",
//   CLIPS = "Clips",
//   ATTACHED_CHANNELS = "Attached Channels"

// }

export interface CoverImage{
  url: string,
  historicEvents: boolean
}
export interface FilterMenuTeams{
  showTeamName: boolean,
  showTime: boolean,
  showUser: boolean,
  showCreatedBy: boolean,
  showCreatedName: boolean
}
export interface FilterInputTeams{
  timeValue: number,
  teamName: string
  userId: string
  adminId: string
}
@Injectable({
  providedIn: "root"
})
export class TeamsService {
  tabId: string
  tabIds:Record<string,string>= {
    HOME:"Home",
    STREAMS:"Streams",
    TEAMS:"Sub-Channels",
    USERS:"Users",
    HIGHLIGHTS:"Highlights",
    CLIPS:"Clips"
  }
  filterMenu: FilterMenuTeams= {showTime:false, showTeamName:false,showUser:false, showCreatedBy:false, showCreatedName: true}
  filterInput: FilterInputTeams={timeValue: 0,teamName:'',userId:'', adminId:''}
  teamsLoader:boolean = false;
  channelTabText: string = ''
  masterChannelTabIds:Record<string,string> = {
    HOME: "Home",
    VIDEOS: "Videos",
    EVENTS: "Events",
    NAMED_TAGS: "Named Tags",
    ATTACHED_CHANNELS: "Attached Channels",
    SETTINGS: "Settings"
  }
  masterChannelTabId: string
  userRole:Role
  roles=Role
  constructor(
    private firestore: AngularFirestore, 
    private router: Router,
    private userSession: AuthGuard,
    private functions: AngularFireFunctions
    ) {
    this.userRole = this.userSession.userRole
    this.tabId = this.tabIds.HOME
    this.setTabId()
    this.masterChannelTabId = this.masterChannelTabIds.HOME
    // this.setMasterTabId()
  }

  setTabId(){
    if(this.userRole===this.roles.SALES){
      this.tabIds={
        HOME:"Home",
        STREAMS:"Streams",
        HIGHLIGHTS:"Highlights",
        CLIPS:"Clips"
      }
    }
  }

  resetTabIds(){
    if(this.userRole===this.roles.SALES) this.setTabId()
    else{
      this.tabIds = {
        HOME:"Home",
        STREAMS:"Streams",
        TEAMS:"Sub-Channels",
        USERS:"Users",
        HIGHLIGHTS:"Highlights",
        CLIPS:"Clips"
      }
    }
  }

  // setMasterTabId(){
  //   if(this.userRole===this.roles.SALES){
  //     this.masterChannelTabIds={
  //       HOME: "Home",
  //       VIDEOS: "Videos",
  //       EVENTS: "Events",
  //       NAMED_TAGS: "Named Tags",
  //       CLIPS: "Clips",
  //       ATTACHED_CHANNELS: "Attached Channels",
  //       SETTINGS: "Consent & Terms"
  //     }
  //   }
  // }

  getTeamsData() {
    return this.firestore.collection("teams").snapshotChanges().pipe(
      map( changes => {
        return changes.map(a => {
          const data = a.payload.doc.data();
         return data;
        });
      })
    );
  }

  getTeamsDataBetweenDates(dateRange: any) {
    return this.firestore.collection(
      'teams',
      ref => ref
        .orderBy('createdTime', 'asc')
        .where('createdTime', '>=', new Date(dateRange.fromDate))
        .where('createdTime', '<=', new Date(dateRange.toDate))
    )
      .snapshotChanges()
      .pipe(
        map( changes => {
          return changes.map(a => {
            const data = a.payload.doc.data() as {};
            const docId = a.payload.doc.id;
            return { docId, ...data };
          });
        }));
  }

  getTeamsDataForClub(clubId: string) {
    return this.firestore.collection(
      'teams',
      ref => ref
        .where('clubDocId', '==', clubId)
    )
      .get()
      .pipe(
        map( changes => {
          return changes.docs.map(doc => {
            const data = doc.data() as any;
            const docId = doc.id;
            return { docId, ...data };
          });
        })).toPromise();
  }

  getTeamsDataByClub(clubId: string, collection?:string){
    if(collection === 'clubs'){
      return this.firestore
      .collection("teams", (ref) => ref.where("clubDocId","==",clubId))
      .valueChanges({ idField: "docId" });
    }
    else if(collection === 'archive')
      return this.firestore.collection("archive").doc(clubId).collection("teams")
      .valueChanges({idField:"docId"})
    return this.firestore
      .collection("teams", (ref) => ref.where("clubDocId","==",clubId))
      .valueChanges({ idField: "docId" });
  }
  getArchivedTeamsData(clubId: string) {
    return this.firestore
      .collection("archive").doc(clubId).collection("teams")
      .valueChanges({ idField: "docId" })
      .pipe(
        map((result) => {
          return result.map((record) => {
            return { isArchived:true, ...record };
          });
        })
      );
  }
  getTeamById(id:string){
    return this.firestore.collection("teams").doc(id).valueChanges()
  }
  deleteTeam(teamId:string){
    return this.firestore.collection("teams").doc(teamId).delete()
  }
  retrieveTeam(clubId:string,teamId:string){
    return this.firestore.collection("archive").doc(clubId).collection("teams").doc(teamId).delete()
  }
  uploadCoverImage(teamId: string, obj: {coverImage: CoverImage}){
    return this.firestore.collection("teams").doc(teamId).update(obj)
  }
  createTeam(teamDocId: string, newTeam: Team){
    return this.firestore.collection("teams").doc(teamDocId).set(newTeam)
  }

  editTeamLogo(docId:string, url:String){
    return this.firestore.collection("teams").doc(docId).update({imageUrl:url})
  }

  updateTeam(docId: string, defaultTags: string[], defaultEventId: string|null){
    return this.firestore.collection("teams").doc(docId).update({
      "channelDetails.defaultTags": defaultTags,
      "channelDetails.defaultEventId": defaultEventId
    })
  }

  // removeShareAppTags(docId:string, tag:string){
  //   this.firestore.collection("teams").doc(docId).update({
  //     "channelDetails.availableTags": firebase.firestore.FieldValue.arrayRemove(
  //       tag
  //     )
  //   })
  // }

  async removeShareAppTags(docId:string, tag:string){
    return await this.functions.httpsCallable("teams-updateeventsandtags")({availableTags: tag, teamDocId: docId}).toPromise()
  }

  addShareAppTags(docId:string, tag:string[]){
    this.firestore.collection("teams").doc(docId).update({
      "channelDetails.availableTags": firebase.firestore.FieldValue.arrayUnion(
        ...tag
      )
    })
  }

  addShareAppEvents(docId:string, addedEvent:string){
    return this.firestore.collection("teams").doc(docId).update({
      "channelDetails.availableEvents": firebase.firestore.FieldValue.arrayUnion(
        addedEvent
      )
    })
  }

  async removeShareAppEvents(docId:string, eventId:string){
    return await this.functions.httpsCallable("teams-updateeventsandtags")({availableEvents: eventId, teamDocId: docId}).toPromise()
  }

  async getTeamDataByClubIdArray(clubDocIds: string[]){
    return (
      await Promise.all(
        chunk(clubDocIds,10).map(
          slice => {
            let query = this.firestore.collection("teams").ref.where("clubDocId","in",slice);
            return query.get();
          }
        )
      )
    ).reduce((acc:any, batchResult) => [...acc, ...batchResult.docs], []);
}
}
