import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import { auditTime } from 'rxjs/operators';

export enum InviteStatus{
  INVITE_SENT = "INVITE_SENT",
  INVITE_ACCEPTED = "INVITE_ACCEPTED"
}
@Injectable({
  providedIn: 'root'
})
export class ClubRegistrationsService {

  constructor(
    private fireStore: AngularFirestore,
    private functions: AngularFireFunctions,
  ) { }
  getClubsData() {
    return this.fireStore
      .collection("clubRegistrations", (ref) => ref.where("status","==",InviteStatus.INVITE_SENT))
      .valueChanges({ idField: "id" }).pipe(auditTime(700));
  }
  getClubById(clubRegistrationId: string){
    return this.fireStore.collection("clubRegistrations").doc(clubRegistrationId)
      .valueChanges()
  }
  updateClubById(clubRegistrationId:string, obj:any){
    return this.fireStore.collection("clubRegistrations").doc(clubRegistrationId).update(obj)
  }
  deleteInvite(docId:string){
    return this.fireStore.collection("clubRegistrations").doc(docId).update({archived:true})
  }
  resendActivationEmail(docId:string){
    return this.functions.httpsCallable("club-sendPreRegisterInvite")({
      clubRegistrationId: docId
     }).toPromise()
  }
}
