import { Component, Input, OnInit } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import lodash from 'lodash';

@Component({
  selector: 'app-table-loader',
  templateUrl: './table-loader.component.html',
  styleUrls: ['./table-loader.component.scss']
})
export class TableLoaderComponent implements OnInit {

  @Input() displayedColumns: string[]=[]
  @Input() noOfRows:number = 5 
  dataList: any = []
  dataSource = new MatTableDataSource<any[]> (this.dataList)
  constructor() { }

  ngOnInit(): void {
    lodash.times(this.noOfRows,()=>this.dataList.push({
      teamName: ''
    }))
    this.dataSource = new MatTableDataSource<any[]> (this.dataList)
  }

}
