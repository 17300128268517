import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  private isBGWhite= new BehaviorSubject<boolean>(false)
  public checkBGWhite= this.isBGWhite.asObservable()

  private isBGColored = new BehaviorSubject<boolean>(false)
  public checkBGColored = this.isBGColored.asObservable()

  constructor() { }
  setBGWhite(){
    this.isBGWhite.next(true)
  }
  resetBGWhite(){
    this.isBGWhite.next(false)
  }
  setBGColored(){
    this.isBGColored.next(true)
  }
  resetBGColored(){
    this.isBGColored.next(false)
  }
}
