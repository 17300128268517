<!-- <div class="modal-body" style="max-height: 400px; overflow: auto; scrollbar-width: none"> -->
<div class="d-flex dialog-body">
   <div class="dialog-align">
      <img src="/assets/Alert_box/warning_icon.svg" class="warning-icon">
   </div>
   <div class="body-container">
      <div class="message" *ngIf="!data.isDuration">
         <span>File not supported</span>
         <p [hidden]="data.isVideo"> {{errorMessage}} </p>
         <p *ngIf="data.isVideo">File not supported. Video size exceeds 20MB limit. </p>
         <p>{{data.message}}</p>
      </div>
      <div class="message" *ngIf="data.isDuration">
         <p>{{data.message}}</p>
      </div>
      <div class="button-div">
      <button type="button" class="btn btn-default btn-grey" mat-dialog-close>Okay</button>
      </div>
   </div>
   <img src="/assets/Alert_box/close_icon.svg" mat-dialog-close class="close-icon">
</div>
 <!-- </div> -->

 <!-- <div class="modal-footer"> -->
    <!-- <ng-container> -->
    <!-- </ng-container> -->
 <!-- </div> -->