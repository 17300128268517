import { Injectable } from '@angular/core';

export enum StreamTabId{
  SCHEDULED='SCHEDULED',
  MANUALLY_ARCHIVED ="ARCHIVED",
  AUTO_ARCHIVED="AUTO_ARCHIVED",
  TEST='TEST'
}
@Injectable({
  providedIn: 'root'
})
export class FormrestoreService {
  formData:any
  isNavigate:boolean
  createdAd: any;

  isEditSeason: boolean
  isSeasonTicketEdited: boolean
  isSeasonTicketCreated: boolean

  streamTabId:StreamTabId
  streamTabIds=StreamTabId
  
  streamRowId: string
  seasonRowId: string
  adRowId: string
  
  streamSearchText: string
  ticketSearchText: string
  adSearchText: string
  channelSearchText: string

  fromScheduler:boolean;
  createdSeasonTicket:any;
  
  isChannelFilter: boolean=false
  isTeamFilter: boolean=false
  constructor() {
    this.isNavigate=false
    this.isEditSeason=false
    this.isSeasonTicketEdited=false
    this.isSeasonTicketCreated=false
    this.streamRowId=''
    this.seasonRowId=''
    this.adRowId=''
    this.streamSearchText=''
    this.ticketSearchText=''
    this.adSearchText=''
    this.channelSearchText=''
    this.fromScheduler = false;

    this.streamTabId= this.streamTabIds.SCHEDULED
   }
}
