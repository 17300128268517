
<div class="main-container">
    <div class="dialog-body">
        <div class="dialog-body-content">
            <div class="dialog-align">
                <img src="../../../assets/Alert_box/success_tick.svg">
            </div>
            <div>
                <h2 class="message"> {{data.successMessage}}</h2>
                <p *ngIf="data.isVideo" style="font-size: 15px;">Please wait sometime for the video to get reflected in the table.</p>
                <p *ngIf="data.isClip" style="font-size: 15px"> You can view the generated clip in the clips 
                    table on the corresponding channel details page.Please wait sometime for the clip to get reflected in the table.</p>
            </div>
        </div>
        <img class="close-dialog" (click)="dialogRef.close()" src="../../../assets/Alert_box/close_icon.svg">
    </div>
    
</div>
 <!-- <div class="modal-footer">
    <button type="button" class="btn btn-default btn-white" (click)="dialogRef.close()">Close</button>
 </div> -->