import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';

import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { I } from '@angular/cdk/keycodes';
import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { keyBy } from 'lodash';
import { AngularFirestore } from '@angular/fire/compat/firestore';

export type UploadStatus = "WAITING" | "STARTED" | "PAUSED" | "FINISHED" | "FAILED";
interface FieldToUpdate {
  collectionPath: string;
  docId: string;
  key: string;
}
export class Upload {

    docId: string ='';
    file?: File;
    base64?: string;
    name: string ='';
    url: string ='';
    progress: number = 0;
    status: UploadStatus;
    createdAt: Date = new Date();
    metadata: firebase.storage.UploadMetadata;
  
    constructor(data: any, base64: boolean = false) {
      if (base64) {
        this.base64 = data;
      } else {
        this.file = data;
      }
    }
  
  }

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  private basePath: string = '/uploads';
  private uploadTask: any;

  constructor(
    private storage : AngularFireStorage,
    private functions: AngularFireFunctions,
    private http: HttpClient,
    private firestore: AngularFirestore
  ) { 
  }

  pushUpload(upload: Upload, path: string, base64: boolean = true): firebase.storage.UploadTask {
    // firebase.initializeApp(environment.firebase);
    // let storageRef = firebase.storage().ref();
    const destination = [this.basePath, path, upload.name.toLowerCase().split(" ").join("-")].join("/");

    const ref = this.storage.ref(destination);
    this.uploadTask = base64 ? ref.putString(upload.base64.split(',')[1], 'base64', upload.metadata) : ref.put(upload.file, upload.metadata);
    setTimeout(()=>{
      if(!window.navigator.onLine) this.uploadTask.cancel()
    },15000)
    return this.uploadTask;

  }

  async adVideo(adDocId: string, file: any): Promise<any> {

    const promises=file.map(async (element: any) => {
      const response = await this.functions
        .httpsCallable("ads-uploadUrl")({ fileName: `${adDocId}/${element.name}`, contentType: element.type })
        .toPromise();

        const fileResponse = await this.http.put(response.signedUrl, element, {headers: {
          'Content-Type': element.type
        } }).toPromise()

        return response.publicUrl
    })
    const publicUrl= await Promise.all(promises)
    return publicUrl
  }
  pushNotificationUpload(upload: Upload, path: string, base64: boolean = true): firebase.storage.UploadTask {
    const destination = [path, upload.name].join("/");

    const ref = this.storage.ref(destination);
    this.uploadTask = base64 ? ref.putString(upload.base64.split(',')[1], 'base64', upload.metadata) : ref.put(upload.file, upload.metadata);
    return this.uploadTask;

  }

  async uploadStreams(streamList: any, ownerDocId: string, clubDocId: string, teamDocId:string): Promise<any> {
    const response = await this.functions
        .httpsCallable("livestreams-bulkImport")({livestream:streamList,ownerDocId:ownerDocId, clubDocId:clubDocId, teamDocId:teamDocId })
        .toPromise();

    return response.data
  }
  async uploadHighlights(highlightId: string, channelDocId: string, file: File){
    const response = await this.functions
        .httpsCallable("ads-uploadUrl")({ fileName: `shorts/${channelDocId}/${highlightId}.mp4`, contentType: file.type })
        .toPromise();

    const fileResponse = await this.http.put(response.signedUrl, file, {headers: {
          'Content-Type': file.type
    } }).toPromise()

    return response.publicUrl
  }

  async channelVideo(channelDocId: string, file: File, country: string, videoName:string): Promise<any> {
    // const promises=file.map(async (element: any) => {
      const response = await this.functions
        .httpsCallable("videos-uploadUrl")({ fileName: `${channelDocId}/${videoName}.mp4`, contentType: file.type, country: country})
        .toPromise();
    try{
      const fileResponse = await this.http.put(response.signedUrl, file, {headers: {
        'Content-Type': file.type
      } }).toPromise()

      const result = {
        inputUrl: response.s3Url,
        region: response.targetRegion
      }

      return result
    }
    catch(error){
      // console.error(error)
      return {error: error}
    }

    // })
    // const publicUrl= await Promise.all(promises)
    // return publicUrl
  }

  async multipartUpload(channelDocId: string, file: File, country: string, videoName:string){
    const FILE_CHUNK_SIZE = 5 * 1024 * 1024 * 1024
    const fileSize = file.size
    const PARTS = Math.floor(fileSize / FILE_CHUNK_SIZE) + 1;
    try{
      const response = await this.functions
      .httpsCallable("videos-uploadUrl")(
        { 
          fileName: `${channelDocId}/${videoName}.mp4`, 
          contentType: file.type, 
          country: country,
          parts: PARTS.toString(),
          requestType: "START_UPLOAD"
        })
      .toPromise();

      let signedUrls = keyBy(response.parts,"PartNumber")
      let start, end, blob;

      let uploadPartsArray: { ETag: string; PartNumber: number | undefined; }[] = [];

      let orderData: { presignedUrl: string; index: number; }[] = [];
      let promises = []

      for (let index = 1; index < PARTS + 1; index++) {
        start = (index - 1) * FILE_CHUNK_SIZE;
        end = (index) * FILE_CHUNK_SIZE;
        blob = (index < PARTS) ? file.slice(start, end) : file.slice(start);

        orderData.push({
          presignedUrl: signedUrls[index].signedUrl.toString(),
          index: index
        });

        const req = new HttpRequest('PUT', signedUrls[index].signedUrl.toString(), blob);

        const putResponse = this.http.request(req).toPromise()
        promises.push(putResponse)
      }

      const uploadedparts = await Promise.all(promises)
      uploadedparts.forEach((parts:any, index)=> {
        const currentPresigned = orderData.find(item => item.presignedUrl === parts.url);
        let etag = parts.headers.get('ETag') || ''
        uploadPartsArray.push({
          ETag: etag.replace(/[|&;$%@"<>()+,]/g, ''),
          PartNumber: currentPresigned? currentPresigned.index : index+1
        });
      })

      if (uploadPartsArray.length === PARTS) {
        const finalResponse = await this.functions.httpsCallable("videos-uploadUrl")({
          requestType:"COMPLETE_UPLOAD",
          fileName: `${channelDocId}/${videoName}.mp4`, 
          contentType: file.type, 
          country: country,
          uploadId: response.uploadId,
          parts: uploadPartsArray,
          bucket: response.bucket,
          region: response.targetRegion[0]
        }).toPromise()

        const result = {
          inputUrl: finalResponse.s3Url,
          region: response.targetRegion
        }

        return result
      }
      else{
        return {error: "The Upload failed"}
      }
    }
    catch(error){
      return {error: error}
    }
    
  }

  setImageTransformJobs(obj: {
    channelDocId: string,
    fieldToUpdate: FieldToUpdate,
    inputUrl: string,
    type: string,
    userDocId: string, 
    createdTime: Date
  }){
    return this.firestore.collection("imageTransformationJobs").add(obj)
  }
}
