import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { capitalize, chain, groupBy, keyBy, max, times, uniq, uniqBy } from 'lodash';
import { DownloadCSVService } from 'src/app/shared/downloadCSV.service';
import { UserService } from 'src/app/shared/users.service';
import { VideosService } from 'src/app/shared/videos.service';


interface BuyersList{
  docId: string,
  email: string,
  typeOfAccess: string,
  price: string | number,
  currency: string,
}
@Component({
  selector: 'app-video-buyers-list',
  templateUrl: './video-buyers-list.component.html',
  styleUrls: ['./video-buyers-list.component.scss']
})
export class VideoBuyersListComponent implements OnInit {
  excelData: any=[]
  isLoader: boolean = true
  displayedColumns: string[] = ["email","typeOfAccess", "price"]
  dataList: BuyersList[]=[] 
  dataSource = new MatTableDataSource<BuyersList> (this.dataList);
  constructor(
    private userService: UserService,
    private videoService: VideosService,
    private downloadCSVService: DownloadCSVService,
    @Inject(MAT_DIALOG_DATA)public data:{channelId: string,videoId: string, channelName: string,
      videoName: string}
  ) {
    times(2,()=> this.dataList.push({
      email: '',
      docId:'',
      typeOfAccess: '',
      price: '',
      currency: '',
    }))
   }

  ngOnInit(): void {
    this.getAccessLogs()
  }

  async getAccessLogs(){
    const accessLogs = await this.videoService.getAccessLogEntries(this.data.channelId, this.data.videoId)
    // const uniqueTickets = uniqBy(accessLogs,'userId')
    // displaying unique userId with latest timestamp
    const uniqueTickets = chain(accessLogs).groupBy('userId').map((logs: any)=> this.getAccessLogsWithMaxTimestamp(logs)).value()
    const userIds = uniqueTickets.map(tickets=> tickets.userId)

    const userDocs = await this.userService.getUsersByBatch(userIds)
    const buyersEmail = userDocs.map((item:any) => ({docId: item.id,email:item.data().email || 'N/A'}))
    
    const buyersMap = keyBy(buyersEmail, 'docId')

    const planId =  uniqueTickets.filter(item=> item.accessType.type === 'SUBSCRIPTION').map(item=> item.accessType.planId)

    let plans = await this.videoService.getPlansByBatch(this.data.channelId,uniq(planId))
    plans = chain(plans).map((item:any)=> ({docId: item.id,...item.data()})).keyBy('docId').value()
    this.dataList = uniqueTickets.map(item=>({
      docId: item.userId,
      email: buyersMap[item.userId]?.email || "N/A",
      typeOfAccess: capitalize(item.accessType.type),
      price: this.getPrice(item.accessType, plans),
      currency: this.getCurrency(item.accessType).toUpperCase()
    }))
    this.dataSource = new MatTableDataSource(this.dataList)
    
    this.isLoader = false

    
  }
  getAccessLogsWithMaxTimestamp(logs: any){
    return chain(logs).map((log:any)=> log).maxBy('timestamp').value()
  }

  getPrice(accessType: any, plans: any){
    if(accessType.type === 'FREE') 
      return 'N/A'
    if(accessType.type === 'TICKET')
      return accessType.ticketEntries[0].entry.selectedConfig.price
    if(accessType.type === 'SUBSCRIPTION'){
      const priceAlternatives =  plans[accessType.planId].priceAlternatives.filter((price:any)=> price.currency === accessType.subscriptionEntry.selectedConfig.currency)
      return !priceAlternatives.length ? 'N/A' : priceAlternatives[0].billingPeriods.filter((bill: any)=>
      bill.period === accessType.subscriptionEntry.selectedConfig.periodicity).map((bill: any)=> bill.basePrice)
    }
      
  }

  getCurrency(accessType: any){
    if(accessType.type === 'FREE') 
      return ''
    if(accessType.type === 'TICKET')
      return accessType.ticketEntries[0].entry.selectedConfig.currency
    if(accessType.type === 'SUBSCRIPTION')
      return accessType.subscriptionEntry.selectedConfig.currency
  }

  exportToExcel(){
    if(this.dataList.length === 0 ) return
    this.excelData = this.dataList.map((item: any) =>
    ({
      "Email": item.email,
      "Type Of Access": item.typeOfAccess,
      "Currency": item.currency || 'N/A',
      "Price": item.price,
    }))
    this.downloadCSVService.downloadFile(this.excelData, 
      `${this.data.channelName}-${this.data.videoName}-BuyersList`,
      Object.keys(this.excelData[0])
      )
  }

}
