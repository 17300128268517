<div class="d-flex mob-d-block content">
    <div class="dialog-body flex-centered">
        <div class="dialog-align">
            <img src="assets/Alert_box/green_tick.svg">
        </div>
            <h2 class="message"> {{data.successMessage}}</h2>
    </div>
</div>
 <div class="modal-footer">
    <button type="button" class="btn btn-default btn-white" (click)="dialogRef.close()">Okay</button>
 </div>