import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { chunk, slice } from 'lodash';

import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import firebase from "firebase/compat/app";
import { map } from 'rxjs/operators';
import moment from 'moment';

export enum VideoCategoryId{
  SHORT='SHORT',
  UPLOAD='UPLOAD',
  STREAM='STREAM',
  RATING='RATING'
}
enum VideoStatusId{
  ACTIVE='ACTIVE',
  SCHEDULED='SCHEDULED',
  ARCHIVED='ARCHIVED'
}
@Injectable({
  providedIn: 'root'
})
export class VideosService {
  searchText: string = ''
  categoryTabId:VideoCategoryId
  categoryTabIds=VideoCategoryId
  selected = {startDate: moment().subtract(6, 'days'), endDate: moment()};
  statusTabId:VideoStatusId
  statusTabIds=VideoStatusId
  private callFunction = new Subject<any>()
  constructor(
    private functions: AngularFireFunctions,
    private firestore: AngularFirestore,
  ) {
    this.categoryTabId = this.categoryTabIds.STREAM
    this.statusTabId = this.statusTabIds.SCHEDULED
   }

  createLiveStream(streamObj:any){
    return this.functions.httpsCallable("livestreams-createLivestream")({
      livestream: streamObj}).toPromise()
  }

  getVideoByDocId(channelDocId: string,streamId: string){
    return this.firestore.collection("channels").doc(channelDocId).collection("videos").doc(streamId).valueChanges()
  }

  updateVideoByDocId(channelDocId: string, streamId: string, obj: any){
    return this.firestore.collection("channels").doc(channelDocId).collection("videos").doc(streamId).update(obj)
  }

  updateVideo(channelId:string, videoId:string, obj:any){
    return this.firestore.collection("channels").doc(channelId).collection("videos").doc(videoId).update(obj)
  }

  removeStreamTag(streamId:string, tag:string){
    this.firestore.collection("livestreams").doc(streamId).update({
      "tags": firebase.firestore.FieldValue.arrayRemove(
        tag
      )
    })
  }

  removeShortsUploadsTag(channelId:string, videoId:string, tag:string){
    this.firestore.collection("channels").doc(channelId).collection("videos").doc(videoId).update({
      "tags": firebase.firestore.FieldValue.arrayRemove(
        tag
      )
    })
  }

  addStreamTag(streamId:string, tag:string[]){
    this.firestore.collection("livestreams").doc(streamId).update({
      "tags": firebase.firestore.FieldValue.arrayUnion(
        ...tag
      )
    })
  }

  addShortsUploadsTag(channelId:string, videoId:string, tag:string[]){
    this.firestore.collection("channels").doc(channelId).collection("videos").doc(videoId).update({
      "tags": firebase.firestore.FieldValue.arrayUnion(
        ...tag
      )
    })
  }

  async getVideosByDocIds(streamDocIds: string[],channelDocId: string){
    const docID = firebase.firestore.FieldPath.documentId()
    return (
      await Promise.all(
        chunk(streamDocIds,10).map(
          slice => {
            let query = this.firestore.collection("channels").doc(channelDocId).collection("videos").ref.where(docID,"in",slice);
            return query.get();
          }
        )
      )
    ).reduce((acc:any, batchResult) => [...acc, ...batchResult.docs], []);
  }
  getVideoSourceBydateRange(docId:string, dateRange : any){
    let query: AngularFirestoreCollection = this.firestore.collection("channels").doc(docId).collection("videos")
    if(dateRange.fromDate && dateRange.toDate)
      query = this.firestore.collection("channels").doc(docId).collection("videos", ref => 
      ref.orderBy('uploadTime', 'desc')
      .where('uploadTime', '>=', new Date(dateRange.fromDate))
      .where('uploadTime', '<=', new Date(dateRange.toDate)))
    else if(dateRange.fromDate)
      query = this.firestore.collection("channels").doc(docId).collection("videos", ref => 
      ref.orderBy('uploadTime', 'desc')
      .where('uploadTime', '>=', new Date(dateRange.fromDate)))
    else if(dateRange.toDate)
      query = this.firestore.collection("channels").doc(docId).collection("videos", ref => 
      ref.orderBy('uploadTime', 'desc')
      .where('uploadTime', '<=', new Date(dateRange.toDate)))
    
    return query.valueChanges({idField:"id"})

    // return this.firestore.collection("channels").doc(docId).collection("videos", ref => 
    // ref.orderBy('uploadTime', 'desc')
    // .where('uploadTime', '>=', new Date(dateRange.fromDate))
    // .where('uploadTime', '<=', new Date(dateRange.toDate))).valueChanges({idField:"id"})
  }
  
  copyVideoLink(livestreamDocIds: string[], type: "LIVESTREAM"|"CLIP"){
    return this.functions.httpsCallable("livestreams-getDownloadLinks")({
      type: type,
      livestreamDocIds: livestreamDocIds}).toPromise()
  }
  sendClickEvent(){
    this.callFunction.next(null)
  }
  getClickEvent(){
    return this.callFunction.asObservable()
  }

  getAccessLogEntries(channelDocId: string, videoId: string){
    return this.firestore.collection("channels").doc(channelDocId).collection("videos")
    .doc(videoId).collection("access-log-entries").get()
    .pipe(
      map( changes => {
        return changes.docs.map(doc => {
          const data = doc.data() as any;
          const docId = doc.id;
          return { docId, ...data };
        });
      })).toPromise()
  }

  async getPlansByBatch(channelDocId: string,idList: string[]){
    const docID = firebase.firestore.FieldPath.documentId()
    return (
      await Promise.all(
        chunk(idList,10).map(
          slice =>{
          let query = this.firestore.collection("channels").doc(channelDocId).collection("plans").ref.where(docID,"in",slice);
          return query.get();}
        )
      )
    ).reduce((acc:any, batchResult)=> [...acc,...batchResult.docs],[]);
  }
  getVideoByEventId(eventId: string,channelDocId:string){
    return this.firestore.collection("channels").doc(channelDocId).collection("videos"
    ,ref=>ref.where('eventId','==',eventId)
    ).valueChanges()
  }

  getStreamsByClubId(id: string) {
    return this.firestore.collection(
      'livestreams',
      ref => ref
        //.orderBy('scheduledStopTime', 'desc')
        .where('clubDocId','==',id)
    )
      .snapshotChanges()
      .pipe(
        map( changes => {
          return changes.map(a => {
            const data = a.payload.doc.data() as {};
            const docId = a.payload.doc.id;
            return { docId, ...data };
          });
        }));
  }
}
