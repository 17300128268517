<div class="image-box" [ngClass]="tabType === 'EVENT' ? 'event-image-box-width' : 'tag-image-box-width'" [ngStyle]="posterCoverImage && {'border':'none'}">
    <div class="d-flex justify-content-center align-items-center" *ngIf="posterCoverImage">
        <ngx-skeleton-loader [theme]="{'border-radius': '5px',height: '272px',width:'482px'}" *ngIf="!imageLoaded && tabType === 'EVENT'">
            </ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{'border-radius': '5px',height: '272px',width:'816px'}" *ngIf="!imageLoaded && tabType === 'NAMED_TAG'">
            </ngx-skeleton-loader>
       <img [src]="posterCoverImage" alt="" class="image-img" (load)="onLoadImage()" [ngClass]="tabType === 'EVENT' ? 'event-image-width' : 'tag-image-width'" [ngStyle]="!imageLoaded && {'display': 'none'}">
    </div>
    <div class="d-flex justify-content-center align-items-center flex-column" *ngIf="!posterCoverImage"
    (dragover)="onDragOver($event)" (drop)="onDropSuccess($event, tabType)">
        <img height="27" width="25" style="margin-top: 4px;" src="assets/Channels/image-placeholder.svg">
        <input style="display: none;" #image type="file" id="cover-image" (change)="imageUpload($event, tabType)"
        accept="image/png, image/jpeg, .png, .jpg">
        <span class="mt-3">
            <label style="cursor: pointer;" for="cover-image" class="select-file">Select file</label>
            or Drag it here
        </span>
        <span class="tag-cover-span">
            File may be upto 2mb jpg, png
        </span>
    </div>
    <div class="image-kebab d-flex align-items-center justify-content-center" [matMenuTriggerFor]="posterMenu"
    *ngIf="posterCoverImage">
        <img src="assets/Channels/image_actions/menu.svg" alt="">
    </div>
    <mat-menu #posterMenu="matMenu" xPosition="before" yPosition="below" class="image-menu">
        <div class="image-option">
            <label mat-menu-item for="reuploadButton" class="upload-button mb-0">Replace</label>
                <input #image type="file"accept="image/png, image/jpg, .png, .jpg"
                title="" id="reuploadButton" (change)="imageUpload($event, tabType)"/>
            <mat-divider style="width: 86px; margin-left: 16px" *ngIf="tabType === 'NAMED_TAG'"></mat-divider>
            <button mat-menu-item (click)="removeImage()" *ngIf="tabType === 'NAMED_TAG'">Remove</button>    
        </div>
    </mat-menu>

 </div>