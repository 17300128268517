<div class="d-flex dialog-body">
    <div class="body-container">
        <div class="message">
            <div>
                <span>{{data.header}}</span>
                <p>{{data.failureMessage}}</p>
            </div>
            <img src="../../../assets/Alert_box/close_icon.svg" (click)="dialogRef.close()" class="close-icon">
        </div>
        <div class="button-div" *ngIf="data.showClose">
            <button type="button" class="btn btn-default btn-red" (click)="dialogRef.close()">Okay</button>
        </div>
    </div>
 </div>