import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import { AttributeKey, AttributeKeys, CustomAttributes,VideoAttributeValue, VideoAttributesMapping } from '@yoimo/interfaces';

export interface StreamData extends CustomAttributes{
  description: string,
  name: string,
  streamingMethod: string,
  streamVisibility: string,
  price: number,
  vodPrice: number,
  scheduledStartTime: string,
  scheduledStopTime: string,
  startDate: string,
  startTime: string,
  duration: string,
  sportyDocId: string|null,
  groupDocId?: string,
  restrictedTo?: string[],
  timezone?:string,
  tags?:string[],
  imageUrl?:string
}

export interface ReservedInputParam {
  rtmpUrl?: string;
  region: string
  id: string;
  arn: string
}

@Injectable({
  providedIn: 'root'
})
export class BulkImportService {
  clubId:string
  teamId:string
  teamName:string
  ownerDocList:any[]
  seasonTicketsList:any
  csvData:any[] = []
  selectedEventsWithOwners:any
  selectedOwner:string
  selectedSeasonTicket:any

  selectedEventsWithSeason:any
  uploadingStream:boolean;
  eventsToBeUploaded:StreamData[]
  clubName: string=''
  private chosenOwner: BehaviorSubject<Object>
  private startEdit: BehaviorSubject<Object>
  shareSelectedOwner: Observable<Object>
  shareStartEditing: Observable<Object>
  private callFunction = new Subject()
  private callSeasonTable = new Subject()
  fileName:string
  isNewChannel:boolean=false;
  channelId:string = ''
  emailRecipients: string []
  // errorResponses: Record<string, Record<string, string|string[]>> = {}
  errorResponses: {
    [k in string] : {
      errorType: string,
      errorMessages: string[]
    }
  } = {}
  eventSourceDocs: Record<string,string>[] = []
  selectedEvent: string;
  attributeMappings:VideoAttributesMapping['mappings'] = {}
  reservedInput:ReservedInputParam[] = []

  constructor(
    private fireStore: AngularFirestore
  ) { 
    this.clubId=''
    this.teamId=''
    this.teamName=''
    this.selectedOwner=''
    this.selectedEvent=''
    this.ownerDocList = []
    this.uploadingStream = false;
    this.eventsToBeUploaded = []
    this.selectedEventsWithOwners=[]
    this.selectedEventsWithSeason=[]
    this.chosenOwner = new BehaviorSubject<Object> ({owner:this.selectedOwner,hasEvents:false})
    this.startEdit = new BehaviorSubject<Object>({isEdit:false})
    this.shareSelectedOwner =  this.chosenOwner.asObservable()
    this.shareStartEditing = this.startEdit.asObservable()
    this.fileName=''
    this.emailRecipients = []
    this.setErrorResponses()
  }

  resetBulk(){
    this.clubId=''
    this.teamId=''
    this.teamName=''
    this.selectedOwner=''
    // this.ownerDocList = []
    this.uploadingStream = false;
    this.eventsToBeUploaded = []
    this.selectedEventsWithOwners=[]
    this.selectedEventsWithSeason=[]
    this.csvData=[]
    this.selectedSeasonTicket=undefined
    this.chosenOwner.next({owner:'',hasEvents:false})
    this.startEdit.next({isEdit: false})
    this.fileName=''
    this.emailRecipients = []
  }

  resetSelectedValues(){
    this.selectedOwner=''
    this.selectedSeasonTicket=undefined
    this.selectedEventsWithOwners=[]
    this.selectedEventsWithSeason=[]
    this.csvData=[]
    this.chosenOwner.next({owner:'',hasEvents:false})
    this.startEdit.next({isEdit: false})
  }
  setChosenOwner(ownerWithEvents: Object){
    this.chosenOwner.next(ownerWithEvents)
  }
  setStartEdit(edit: Object){
    this.startEdit.next(edit)
  }
  sendForwardEvent(){
    this.callFunction.next(null)
  }
  getForwardEvent(){
    return this.callFunction.asObservable()
  }
  sendPreviousEvent(){
    this.callSeasonTable.next(null)
  }
  getPreviousEvent(){
    return this.callSeasonTable.asObservable()

  }

  getBulkImportData(teamDocId: string){
    return this.fireStore.collection("livestreamBulkImports",ref=>ref.where("teamDocId","==",teamDocId))
    .valueChanges({ idField: "docId" })
  }

  getAttributeValueLabel(attribute: AttributeKey, valueName?: string): string | undefined {
    let value = this.attributeMappings?.[attribute]?.values.find((attributeValue: VideoAttributeValue) => attributeValue.name == valueName)
    return value?.label ? `${value.name}(${value?.label})` : value?.name
  }

  getRtmpUrl(id?: string): string {
    let value = this.reservedInput.find((item: ReservedInputParam) => item.id == id)
    return value?.rtmpUrl ? value?.rtmpUrl : ''
  }

  setErrorResponses(){
    this.errorResponses = {
      "name": {
        errorType: "Name is invalid",
        errorMessages: ["name is invalid", "invalid name"]
      },
      "description": {
        errorType: "Description is invalid",
        errorMessages: ["description is invalid", "invalid description"]
      },
      "date": {
        errorType: "Date is invalid",
        errorMessages: ["scheduled start date should be in the future", "startdate is invalid", "date is invalid", "invalid date", "invalid startdate"]
      },
      "timezone": {
        errorType: "Timezone is invalid",
        errorMessages: ["timezone is invalid"]
      },
      "time": {
        errorType: "Time is invalid",
        errorMessages: ["scheduled start time should be in the future", "invalid time", "invalid startTime", "start time is invalid", "starttime is invalid", "stream limit error"]
      },
      "duration": {
        errorType: "Duration is invalid (It should be between 5 and 720 mins)",
        errorMessages: ["invalid duration", "duration is invalid", "scheduled stop time should be greater than scheduled start time", "duration should be between 5 and 720 minutes"]
      },
      "price": {
        errorType: "Price is invalid",
        errorMessages: ["price is invalid", "invalid price"]
      },
      "vod": {
        errorType: "Vodprice is invalid",
        errorMessages: ["vodprice is invalid", "invalid vodprice"]
      },
      "streamingMethod": {
        errorType: "Streaming method is invalid",
        errorMessages: ["streamingmethod is invalid", "sportydocid is invalid"]
      },
      "reservedInput":{
        errorType: "Conflicting streams detected. The reserved input URL is already in use by another stream. Please choose a different start time / duration and try again",
        errorMessages: ["reserved input conflict"]
      },
      "tags": {
        errorType: "Tags is invalid",
        errorMessages: ["tags is invalid"]
      },
      ...Object.assign(
        {},
        ...AttributeKeys.map(attribute => ({
          [attribute]: {
            errorType: `The attribute ${attribute.slice(-1)} or its value has been modified or removed. You can proceed with Reupload.`,
            errorMessages: [`invalid attr ${attribute.slice(-1)}`]
          }
        }))
      )
    }
  }
}
