<div class="modal-body no-season" style="scrollbar-width:none;">
    <div class="mob-d-block">
             <div class="loader-chart" *ngIf="loading">
               <img src='../../../../assets/joymo-loader.svg'/>
             </div>
             <image-cropper class="season-ticket-cropper" [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="true" [aspectRatio]="aspectRatio" [format]="imageFormat"
                (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
                (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
             </image-cropper>

             <h3 class="cropped-image" *ngIf="!loading">Image Preview</h3>
             <div class="examples" *ngIf="!loading">
                <div class="logo" [ngClass]="{'circle':data.square}">
                   <img [src]="croppedImage" />
                </div>
             </div>
    </div>
</div>

<div class="modal-body season-img" style="scrollbar-width: none;">
   <div class="mob-d-block">
      <h3 class="cropped-image">Image Preview</h3>
         <div class="examples">
            <div class="logo" [ngClass]="{'circle':data.square}">
               <img [src]="croppedImage" />
            </div>
         </div>
   </div>
</div>

 <div class="modal-footer">
       <button type="button" class="btn btn-default btn-white" *ngIf="!data.square"
          (click)="clearFile()">No</button>
       <button type="button" class="btn btn-default btn-green" *ngIf="!data.square" (click)="swapModal()">Yes</button>
       <button type="button" class="btn btn-default btn-white" *ngIf="data.square"
          (click)="clearFile()">Cancel</button>
       <button type="button" class="btn btn-default btn-green" *ngIf="data.square" (click)="swapModal()">Apply</button>
 </div>