import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Injectable } from "@angular/core";
import {Role} from './userSession.service'
import {Router} from "@angular/router";
import { chunk } from "lodash";
import firebase from "firebase/compat/app";
import { map } from "rxjs/operators"
import moment from "moment";
@Injectable({
  providedIn: "root"
})
export class UserService {
  constructor(private firestore: AngularFirestore,
    private router:Router) {}

  getUsersData() {
    return this.firestore.collection("users",
    ref=> ref.where("role","in",[Role.ADMIN,Role.OTT,Role.TECH,Role.FINANCE,Role.CSM,Role.SALES])).snapshotChanges()    
    .pipe(
      map(changes=>{
        return changes.map(change=>{
          const docId= change.payload.doc.id
          const data= change.payload.doc.data() as {}
          return {docId,...data}
        })
      })
    );
  }

  getUserByDocId(id: string) {
    return this.firestore.collection("users").doc(id).ref.get().then((docRef: any) => {
      return docRef.data();
    })
  }

  checkUser(email:string,environmentName:string,selectedEmailType?:string){
    let filterRef:string[] = []
    if(environmentName == 'bucs'){
      filterRef = [`${email}@joymo.tv`,`${email}@ncompass.inc`,`${email}@bucs.tv`]
      if(selectedEmailType == '@bucs.tv'){
        filterRef = [`${email}@bucs.tv`,`${email}@ncompass.inc`]
      }
      else{
        filterRef = [`${email}@joymo.tv`,`${email}@ncompass.inc`]
      }
    }
    else{
      filterRef = [`${email}@joymo.tv`,`${email}@ncompass.inc`]
    }
    return this.firestore.collection("users",ref=>
    ref.where("email","in",filterRef)).get()
    .pipe(
      map( changes => {
        return changes.docs.map(doc => {
          const data = doc.data() as any;
          const docId = doc.id;
          return { docId, ...data };
        });
      })
    )
  }

  addUser(role: any, id: string){
    return this.firestore.collection("users").doc(id).update({"role": role,"archived": false,"createdTime":moment().toDate()})
  }

  updateUser(role: any,id: string){
    let obj:any = {
      "archived":false,
      "role":role
    }
    return this.firestore.collection("users").doc(id).update(obj)
  }

  updateUserActivity(id: string, lastActivity: any){
    return this.firestore.collection("users").doc(id).update({"lastActivity": lastActivity})
  }

  deleteUser(id: string){
    let obj:any = {
      archived:true,
      role:null
    }
    return this.firestore.collection("users").doc(id).update(obj)
  }
  async getUsersByBatch(idList: string[]){
    return (
      await Promise.all(
        chunk(idList,10).map(
          slice => 
            this.firestore.collection("users").ref
            .where(firebase.firestore.FieldPath.documentId(),'in',slice)
            .get()
        )
      )
    ).reduce((acc:any, batchResult)=> [...acc,...batchResult.docs],[]);
  }
}
