<div class="bodyBg">
  <div class="login-card">
      <!-- <div class="logo" *ngIf="!isLoader && showLogin">
        <div class="login-div">
          <img class='logo-img' src="../../assets/Joymo_Logo_Dark_Grey_CMYK.svg" alt="logo">
          <label class="joymo-lbl">Welcome to Joymo's Back Office</label>
          <button type="submit" class="login-btn-google" (click)="login()">
            <img class="google-img" src="../../assets/google_logo.svg">Login with Google
          </button>
        </div>
      </div> -->

      <div class="splash-container" [ngClass]="{'loader-on': isLoader}">
        <div  class="splash-div">
          <div class="logo-block">
            <div class="backoffice-logo" *ngIf="!loggedOut" [ngClass]="{'hide':showLogin}">
              <div class="logo-text">
                <img class="logo-text-img" src="assets/Splash_Screen/backoffice-logo.svg">
                <div class="bucs-lbl-loader d-flex"  *ngIf="environmentName === 'bucs'">
                  <div class="bucs-lbl">
                    <label>BUCS Back Office</label>
                  </div>
                  <div class="loading-div">
                    <img src="assets/Splash_Screen/joymo-logo.svg" id="icon1">
                    <img src="assets/Splash_Screen/joymo-logo.svg" id="icon2">
                    <img src="assets/Splash_Screen/joymo-logo.svg" id="icon3">
                  </div>
                </div>
              </div>
              <div class="loading-div" *ngIf="environmentName !== 'bucs'">
                <img src="assets/Splash_Screen/joymo-logo.svg" id="icon1">
                <img src="assets/Splash_Screen/joymo-logo.svg" id="icon2">
                <img src="assets/Splash_Screen/joymo-logo.svg" id="icon3">
              </div>
            </div>
            <div class="login-div" [ngClass]="{'show' : showLogin}">
              <img class='logo-img' src="assets/Splash_Screen/backoffice.svg" alt="logo">
              <label class="joymo-lbl" *ngIf="environmentName !== 'bucs'">Welcome to Joymo's Back Office</label>
              <div class="bucs-lbl" *ngIf="environmentName === 'bucs'">
                <div>Welcome to</div>
                <div>BUCS Back Office</div>
              </div>
              <button type="submit" class="login-btn-google" (click)="login()">
                <img class="google-img" src="assets/google_logo.svg">Login with Google
              </button>
            </div>
          </div>
          <div class="bottom-rectangle">
            <div class="main-players-block">
              <div class="players-div">
                <img src="assets/Splash_Screen/players.svg">
              </div>
            </div>
            <img width="100%" class="bottom-rectangle-img" src="assets/Splash_Screen/Rectangle 2546.png">
          </div>
        </div>
      </div>
      <div class="login-loader" *ngIf="isLoader">
        <img src='../../assets/joymo-loader.svg'/>
      </div>
  </div>
  
</div>