import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-internet-lost',
  templateUrl: './internet-lost.component.html',
  styleUrls: ['./internet-lost.component.scss']
})
export class InternetLostComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<InternetLostComponent>) { }

  ngOnInit(): void {
  }

}
