<div class="modal-header">
    <h2 class="mb-0"> Season Tickets List</h2>
</div>
<div class="modal-body" style="max-height: 400px;overflow: auto; scrollbar-width:none;">
    <div class="d-flex mob-d-block">
        <div>
            <ol style="text-align: left !important;">
                <li *ngFor="let seasonTickets of seasonTicketsAttached">
                    {{seasonTickets}}
                </li>
            </ol>

        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default btn-white" mat-dialog-close>Close</button>
</div>