<div class="modal-header">
    <h2 class="mb-0">{{data.header}}</h2>
</div>
<div class="modal-body">
    <div class="mob-d-block">
        <table mat-table [dataSource]="dataSource" class="stream-table">
            <ng-container matColumnDef="userDocId">
                <th mat-header-cell *matHeaderCellDef>UserDocId</th>
                <td mat-cell *matCellDef="let element">

                    <ngx-skeleton-loader *ngIf="isLoader" [theme]="{
                'border-radius': '5px',
                 height: '15px',
                 'width': '100px',
                 outline: 'none'
              }"></ngx-skeleton-loader>
                    <span *ngIf="!isLoader">{{element?.docId}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="userName">
                <th mat-header-cell *matHeaderCellDef>User Email</th>
                <td mat-cell *matCellDef="let element">

                    <ngx-skeleton-loader *ngIf="isLoader" [theme]="{
                'border-radius': '5px',
                 height: '15px',
                 'width': '100px'
              }"></ngx-skeleton-loader>
                    <span *ngIf="!isLoader">{{element?.email | truncateString: 25}}</span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;let i = index;"></tr>
        </table>

    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default btn-white" mat-dialog-close>Close</button>
</div>