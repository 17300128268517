import {CommonModule} from "@angular/common";
import { NgModule } from '@angular/core';
import { TruncateString } from './custom.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    TruncateString,
  ],
  exports: [
    TruncateString,
  ],
})
export class PipesModule {}
