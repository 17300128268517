import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

type PropUpType = 'Negative' | 'Positive' | 'Neutral' | 'Warning'

@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss']
})
export class DeletePopupComponent implements OnInit {
  btnColor:string;
  borderColor:string;

  constructor(
    public dialogRef:MatDialogRef<DeletePopupComponent>,
    @Inject(MAT_DIALOG_DATA)public data:{
      header:string,message:string,
      buttonText: string, imgSrc: string, 
      imgHide: boolean, subText: string,
      popUpType?: PropUpType, cancelText?:string
    }
  ) {
    this.btnColor = "btn-red" 
    this.borderColor = environment.name == 'bucs' ? "#f24646" : '#DD4848'
    if(data.popUpType){ //==='Reactivate' || data.buttonText==='Continue' || data.buttonText==="Publish" || data.buttonText==="Unarchive" || data.buttonText === 'Enable' || data.buttonText === 'Unlock' || data.buttonText === 'Yes'
      this.btnColor="btn-green"
      this.borderColor= environment.name==='bucs' ? "#2FAC61" : "#1ed761"
    }
    if(data.popUpType == 'Neutral'){
      this.btnColor = 'btn-yellow' 
      this.borderColor= environment.name==='bucs' ? "#F0B220" : "#1ed761"
    }
   }

  ngOnInit(): void {
  }

  confirm() {
    this.dialogRef.close(true);
  }
}
