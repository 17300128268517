import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-undo-snackbar',
  templateUrl: './undo-snackbar.component.html',
  styleUrls: ['./undo-snackbar.component.scss']
})
export class UndoSnackbarComponent implements OnInit {

  constructor(
    public snackbarRef: MatSnackBarRef<UndoSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA)public data:{message:string,type:'cancel | delete'}
  ) { }

  ngOnInit(): void {
  }

  undo(){
    this.snackbarRef.dismissWithAction()
  }

  close(){
    this.snackbarRef.dismiss()
  }

}
