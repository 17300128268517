import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/compat/functions';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthService } from "./shared/auth.service";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CancelPopupComponent } from './material-dialog/cancel-popup/cancel-popup.component';
import { CommonModule } from '@angular/common';
import { ConfirmCancelPopupComponent } from './material-dialog/confirm-cancel-popup/confirm-cancel-popup.component';
import { DeletePopupComponent } from './material-dialog/delete-popup/delete-popup.component';
import { EmailStatusComponent } from './material-dialog/email-status/email-status.component';
import { EventsTagsImageComponent } from './material-dialog/events-tags-image/events-tags-image.component';
import { FailurePopupComponent } from './material-dialog/failure-popup/failure-popup.component';
import { HttpClientModule } from '@angular/common/http';
import { InternetLostComponent } from './material-dialog/internet-lost/internet-lost.component';
import { LivestreamTicketBuyerListComponent } from './material-dialog/livestream-ticket-buyer-list/livestream-ticket-buyer-list.component';
import { LoginComponent } from './login/login.component';
import { MatChipsModule } from '@angular/material/chips'
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from "@angular/material/menu";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { ModalPopupComponent } from './modal-popup/modal-popup.component';
import { NewChannelExperienceComponent } from './material-dialog/new-channel-experience/new-channel-experience.component';
import { NgModule } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PipesModule } from './pipe/pipe.module'
import { CdkScrollableModule, ScrollingModule } from "@angular/cdk/scrolling";
import { SeasonTicketBuyersListComponent } from './material-dialog/season-ticket-buyers-list/season-ticket-buyers-list.component';
import { SeasonTicketListPopupComponent } from './material-dialog/season-ticket-list-popup/season-ticket-list-popup.component';
import { SuccessPopupComponent } from './material-dialog/success-popup/success-popup.component';
import { TeamTagsComponent } from './material-dialog/team-tags/team-tags.component';
import { UsersListComponent } from './material-dialog/users-list/users-list.component';
import { VideoBuyersListComponent } from './material-dialog/video-buyers-list/video-buyers-list.component';
import { environment } from '../environments/environment';
import { EmailRecipientsComponent } from './material-dialog/email-recipients/email-recipients.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { DeleteSnackbarComponent } from './material-dialog/delete-snackbar/delete-snackbar/delete-snackbar.component';
import { RouteReuseStrategy } from "@angular/router";
import { CustomReuseStrategy } from "./route-re-use";
import { UndoSnackbarComponent } from './material-dialog/delete-snackbar/undo-snackbar/undo-snackbar.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ModalPopupComponent,
    DeletePopupComponent,
    SuccessPopupComponent,
    CancelPopupComponent,
    ConfirmCancelPopupComponent,
    InternetLostComponent,
    SeasonTicketListPopupComponent,
    SeasonTicketBuyersListComponent,
    LivestreamTicketBuyerListComponent,
    FailurePopupComponent,
    EmailStatusComponent,
    UsersListComponent,
    NewChannelExperienceComponent,
    TeamTagsComponent,
    VideoBuyersListComponent,
    EventsTagsImageComponent,
    EmailRecipientsComponent,
    DeleteSnackbarComponent,
    UndoSnackbarComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase, "angular-auth-firebase"),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MatTableModule,
    BrowserAnimationsModule,
    MatDialogModule,
    NgxSkeletonLoaderModule.forRoot(),
    PipesModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    ScrollingModule,
    MatChipsModule,
    MatFormFieldModule,
    MatMenuModule,
    MatDividerModule,
    MatAutocompleteModule,
    CdkScrollableModule
  ],
  providers: [AuthService,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy,
    },
    {
      provide: REGION, 
      useValue: environment.firebase.locationId
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
