import { Input,Component, OnInit,OnChanges} from '@angular/core';
declare var $:any;

@Component({
  selector: 'app-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss']
})
export class ModalPopupComponent implements OnInit {
  // @Input() modalShow = false;
  constructor(
  ) { }
 
  ngOnInit(): void {
  }

}
