import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Injectable, NgZone } from '@angular/core';

import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Router } from "@angular/router";
import { GoogleAuthProvider } from '@angular/fire/auth';
@Injectable({
  providedIn: 'root'
})

export class AuthService {

  user: any;
  loggedOut: boolean = false;
  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
  }

  // Sign in with Google
  async loginWithPopup() {
    const provider = new GoogleAuthProvider();
    const params = { prompt: "select_account" };
    provider.addScope('email')
    provider.setCustomParameters(params)
    return this.afAuth.signInWithPopup(provider)
  }

  logout() {
    this.afAuth.signOut().then(res => {
      this.loggedOut = true
      this.router.navigate(['login']);
    });
    localStorage.removeItem("userSession");
  }
  errorMessage(value: boolean) {
    return value;
  }

  cypressLogin(customToken:any){
    return this.afAuth.signInWithCustomToken(customToken)
  }

};



