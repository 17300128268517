import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { OutputFormat } from 'ngx-image-cropper/lib/interfaces/cropper-options.interface';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {
  croppedImage: any=''
  imageChangedEvent: any=''
  loading:boolean=true;
  aspectRatio:number = 2/1
  imageFormat:OutputFormat = 'png'
  constructor(
    public dialogRef: MatDialogRef<ImageCropperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {cropEvent:any, square?:boolean, fromSeason?:boolean, format?: OutputFormat, aspectRatio: number}
  ) { 
    this.imageChangedEvent=this.data.cropEvent;
    if(this.data.fromSeason){
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.croppedImage = event.target.result;
      };
      reader.readAsDataURL(this.data.cropEvent.target.files[0]);
    }
    if(data.square) this.aspectRatio=1/1
  }

  ngOnInit(): void {
    if(this.data.format){
      this.imageFormat = this.data.format
    }
    if(this.data.aspectRatio) this.aspectRatio = this.data.aspectRatio
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage=event.base64
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
    this.loading=false;
  }

  loadImageFailed() {
  }

  clearFile(){
    this.dialogRef.close({cancel:true})
  }

  swapModal(){
    this.dialogRef.close({croppedImage: this.croppedImage});
  }

}
