import { BehaviorSubject, Subject } from 'rxjs';

import { Injectable } from '@angular/core';

export enum ModuleHeader{
  STREAMS="Streams",
  BROKEN_STREAMS="Broken Streams",
  SEASON_TICKETS="Season Tickets",
  ADS="Manage Ads",
  REPORTS="Reports",
  USERS="Joymoicans",
  CHANNELS="Channels",
  EVENTS="Events",
  NAMEDTAGS="Namedtags",
}
export interface BreadCrumbs{
  name: string| ModuleHeader,
  url?: string,
  type?: "EVENT" | "NAMEDTAG" | "STREAM"
}
@Injectable({
  providedIn: 'root'
})
export class BreadCrumbsService {
  private breadCrumbValues: BreadCrumbs[]=[]
  private breadCrumbs= new BehaviorSubject<BreadCrumbs[]>(this.breadCrumbValues)
  public share= this.breadCrumbs.asObservable()
  private callFunction = new Subject<any>()
  constructor() { }
  resetBreadCrumbs(){
    this.breadCrumbValues=[]
    this.breadCrumbs.next(this.breadCrumbValues)
  }
  setBreadCrumbs(breadCrumbs: BreadCrumbs){
    this.breadCrumbValues.push(breadCrumbs)
    this.breadCrumbs.next(this.breadCrumbValues)
  }
  popBreadCrumb(){
    this.breadCrumbValues.pop()
    this.breadCrumbs.next(this.breadCrumbValues)
  }
  sendClickEvent(moduleHeader: ModuleHeader, name?:string){
    let obj;
    if(name){
      obj = {
        moduleHeader: moduleHeader, name: name
      }
    }else{
      obj = moduleHeader
    }
    this.callFunction.next(obj)
  }
  getClickEvent(){
    return this.callFunction.asObservable()
  }
}
