import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { first, map } from 'rxjs/operators';
import { UserService } from './users.service';
import { environment } from 'src/environments/environment';

export enum Role {
  ADMIN = "Admin",
  OTT = "OTT",
  CSM = "CSM",
  FINANCE = "Finance",
  TECH = "Tech",
  SALES = "Sales"
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  userRole!:Role;
  userToken: string | undefined;
  userDocId:string = ''
  environmentName:string = environment.name
  
  constructor(private afAuth: AngularFireAuth, private router: Router, private userService: UserService,) { 
    this.afAuth.idTokenResult.subscribe(res => {
      this.userToken = res?.token
      this.userRole = res?.claims?.role
    });
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.afAuth.authState.pipe(
      first(),
      map(authUser => {
        localStorage.setItem("userSession",JSON.stringify(authUser));
        if (authUser === null) {
          this.router.navigate(['/login']);
          return false;
        }
        this.userDocId = authUser.uid
        return true;
      }
      )
    );
  }
}
