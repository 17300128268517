// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    name: "staging",
    production: false,
    firebase: {
        apiKey: "AIzaSyAJyUKKe9MzNJpHEHRU7P-PU2E9_1ye10w",
        authDomain: "staging.backoffice.joymo.no",
        databaseURL: "https://yoimo-live-staging.firebaseio.com",
        projectId: "yoimo-live-staging",
        storageBucket: "yoimo-live-staging.appspot.com",
        messagingSenderId: "662686805404",
        appId: "1:662686805404:web:1e993d66087d19292aec59",
        measurementId: "G-L1T75PD6KY",
        locationId: 'us-central1'
    },
    demoClubId: 'uM5C8wEubW2o2og76q3R',
    arenaLink: "https://staging.arena.joymo.no/checkout/collections/",
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
  