import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-delete-snackbar',
  templateUrl: './delete-snackbar.component.html',
  styleUrls: ['./delete-snackbar.component.scss']
})
export class DeleteSnackbarComponent implements OnInit {

  constructor(
    public snackbarRef: MatSnackBarRef<DeleteSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA)public data:{successMessage:string}
  ) { }

  ngOnInit(): void {
  }

}
