<div class="modal-header">
    <h2 class="mb-0">{{data.header}}</h2>
    <div class="price-padding">
        <span class="col-lg-7 price-padding"> Livestream - {{livestreamPrice}}</span>
        <span class="col-lg-7 vod-padding"> VOD - {{vodPrice}}</span>
        <span class="col-lg-7 bundle-padding"> Bundle - {{bundlePrice}}</span>
    </div>
</div>
<div class="modal-body">
    <div class="mob-d-block">
        <table mat-table [dataSource]="dataSource" class="stream-table">
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef> email </th>
                <td mat-cell *matCellDef="let element">
                    <ngx-skeleton-loader *ngIf="isLoader" [theme]="{
                        'border-radius': '5px',
                         height: '15px',
                         'width': '100px',
                         outline: 'none'
                      }"></ngx-skeleton-loader>
                    <span *ngIf="!isLoader"> {{element?.email | truncateString: 25}} </span>

                </td>
            </ng-container>
            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef> price </th>
                <td mat-cell *matCellDef="let element">
                    <ngx-skeleton-loader *ngIf="isLoader" [theme]="{
                        'border-radius': '5px',
                         height: '15px',
                         'width': '100px'
                      }"></ngx-skeleton-loader>
                    <span *ngIf="!isLoader">{{element.price}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="purchasedDate">
                <th mat-header-cell *matHeaderCellDef> Purchased Date </th>
                <td mat-cell *matCellDef="let element">
                    <ngx-skeleton-loader *ngIf="isLoader" [theme]="{
                        'border-radius': '5px',
                         height: '15px',
                         'width': '100px'
                      }"></ngx-skeleton-loader>
                    <span *ngIf="!isLoader">{{element.date}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="typeOfPurchase">
                <th mat-header-cell *matHeaderCellDef> Type of Purchase </th>
                <td mat-cell *matCellDef="let element">
                    <ngx-skeleton-loader *ngIf="isLoader" [theme]="{
                        'border-radius': '5px',
                         height: '15px',
                         'width': '100px'
                      }"></ngx-skeleton-loader>
                    <span *ngIf="!isLoader">{{element.typeOfPurchase}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="client">
                <th mat-header-cell *matHeaderCellDef> client </th>
                <td mat-cell *matCellDef="let element">
                    <ngx-skeleton-loader *ngIf="isLoader" [theme]="{
                        'border-radius': '5px',
                         height: '15px',
                         'width': '100px'
                      }"></ngx-skeleton-loader>
                    <span *ngIf="!isLoader">{{element.client}}</span>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <ng-container>
            <div class="text-center mt-4 mb-4" *ngIf="isNoRec">No results found</div>
        </ng-container>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default btn-white" mat-dialog-close>Close</button>
</div>