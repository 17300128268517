<!-- <div class="modal-header">
    <h2 class="modal-title mb-0">{{data.header}}</h2>
 </div> -->
<div class="d-flex dialog-body" [ngClass]="{'coverImage-container':this.data.action === 'coverImage'}">
    <div class="body-container">
        <div class="message">
            <div>
                <span class="message-header">{{data.header}}?</span>
                <p *ngIf="!(data.message == 'editCancel')">{{data.message}}</p>
                <p *ngIf="data.message == 'editCancel'">Looks like you have made some changes. Are you sure you want to cancel the update?</p>
            </div>
            <img src="../../../assets/Alert_box/close_icon.svg" (click)="callModal()" class="close-icon">
        </div>
        <div class="button-div">
            <button type="button" class="btn btn-default btn-grey" (click)="callModal()">No</button>
            <button type="button" class="btn btn-default btn-red" (click)="openCancelModal()">Yes</button>
        </div>
    </div>
 </div>
