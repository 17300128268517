import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AuthService } from "../shared/auth.service";
import { Observable } from "rxjs";
import { PlatformLocation } from "@angular/common";
import { Router } from "@angular/router";
import moment from "moment";
import { InternetLostComponent } from "../material-dialog/internet-lost/internet-lost.component";
import { MatDialog } from "@angular/material/dialog";
import { ModalPopupComponent } from "../modal-popup/modal-popup.component";
import { SettingsService } from '../shared/settings.service';
import { UserService } from '../shared/users.service';
import { environment } from '../../environments/environment';

declare var $: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  host: {'class': `${environment.name}`}
})
export class LoginComponent implements OnInit {
  // modalShow = false;
  isLoader = false;
  showLogin:boolean = false;
  loggedOut:boolean = false;
  authStateCalled:boolean = false;
  environmentName:string
  constructor(
    private authService: AuthService,
    private router: Router,
    private settingsService: SettingsService,
    private userService: UserService,
    public afAuth: AngularFireAuth,
    location: PlatformLocation,
    private dialog: MatDialog
  ) { 
    this.environmentName = environment.name
    let user = localStorage.getItem("userSession");
    let url = localStorage.getItem('isBack');
    if(user == null ) {
      this.checkAuthState();
    } else if(user != "null" && url =='yes'){
      this.checkAuthState();
    } else {
      localStorage.setItem("isBack", "");
      this.router.navigate(["/home/stream-view"]);
    }
  }

  ngOnInit(): void {
    if(!this.authStateCalled) this.checkAuthState()
  }

  checkAuthState(){
    if(!window.navigator.onLine){
      this.openInternetLost()
      return
    }
    this.authStateCalled = true
    this.loggedOut = this.authService.loggedOut;
    let auth = this.afAuth.authState.subscribe((res) => {
      if(res){
        this.showLogin = false;
        this.usersCollection(res.uid);
        this.userService.updateUserActivity(res.uid,moment().toDate())
      }else if(this.authService.loggedOut){
        this.showLogin=true
        this.isLoader=false
      }else{
        setTimeout(()=> {
          this.showLogin = true
          this.isLoader = false
          this.authService.logout();
        },2000)
      }
      auth.unsubscribe()
    })
  }

  async login() {
    if (!window.navigator.onLine) {
      this.openInternetLost();
    } else {
      this.isLoader = true;
      // CustomToken is only for signing in via cypress automation testing
      const customToken = localStorage.getItem("customToken")
      try {
        if (customToken) {
          const cypressUser = await this.authService.cypressLogin(customToken)
          if (cypressUser.user) {
            this.usersCollection(cypressUser.user.uid);
            this.userService.updateUserActivity(cypressUser.user.uid, moment().toDate())
          }
        }
        else {
          const result = await this.authService.loginWithPopup()
          if (result) {
            this.checkAuthState()
          }
        }
      }
      catch (error) {
        this.isLoader = false
        this.showLogin = true;
      }
    }
  }

  openInternetLost() {
    this.dialog.open(InternetLostComponent, {
      width: "450px",
      panelClass: "internet-lost",
      position: {
        top: "180px",
      },
      // disableClose: true,
    });
  }

  usersCollection = (id: any) => {
    return this.settingsService
      .getSettingsData(id)
      .then((result) => {
        // this.modalShow = false;
        localStorage.setItem("isBack", "");
        this.router.navigate(["home/stream-view"]);
        // this.isLoader = false;
      })
      .catch((err) => {
        this.openError()
        this.isLoader = false;
        this.showLogin = true;
        this.authService.logout();
      });

    // .subscribe(
    //   result => {
    //     this.modalShow = false;
    //     localStorage.setItem('isBack', '')
    //     this.router.navigate(['home/stream-view']);
    //   },
    //   error => {
    //     this.modalShow = true;
    //     this.authService.logout();
    //   }
    // );
  };

  openError(){
    this.dialog.open(ModalPopupComponent,{
      width:'468px',
      position:{
        'top':'175px'
      },
      disableClose:true,
      panelClass:"cancel-action"
    })
  }
}
