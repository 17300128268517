<div class="modal-header">
  <h2 class="mb-0">{{header}}</h2>
  <div class="float-right" >
    <button type="submit" class="export-btn" (click)="exportToExcel()" [ngStyle]="isLoader && {'cursor':'default'}">
      <img class="export-img" src="assets/Datatable/export_excel.svg"><span class="mob-d-none">Export</span>
    </button>
  </div>
</div>
<div class="modal-body">
  <div class="mob-d-block">
      <table mat-table [dataSource]="dataSource" class="stream-table">
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let element">
            
            <ngx-skeleton-loader *ngIf="isLoader" [theme]="{
              'border-radius': '5px',
               height: '15px',
               'width': '100px'
            }"></ngx-skeleton-loader>
            <span *ngIf="!isLoader">{{element?.email | truncateString: 25}}</span>
          </td>
        </ng-container>


        <ng-container matColumnDef="noOfTicketsPurchased">
          <th mat-header-cell *matHeaderCellDef>No of Tickets Purchased</th>
          <td mat-cell *matCellDef="let element">
            <ngx-skeleton-loader *ngIf="isLoader" [theme]="{
              'border-radius': '5px',
               height: '15px',
               'width': '100px'
            }"></ngx-skeleton-loader>
            <span *ngIf="!isLoader">{{element?.noOfTicketsPurchased}}</span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;let i = index;"></tr>
      </table>

  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default btn-white" mat-dialog-close>Close</button>
</div>